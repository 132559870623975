import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import patientReducer from "./features/patientSlice";
import paymentReducer from "./features/paymentSlice";
import antecedentReducer from "./features/antecedentsSlice";
import citaReducer from "./features/citasSlice";
import visitaReducer from "./features/visitasSlice";
import recetaReducer from "./features/recetaSlice";
import estudioReducer from "./features/estudiosSlice";
import notificationReducer from "./features/notificationSlice";
import configurationReducer from "./features/configurationSlice";

export const store = configureStore({
  reducer: {
    patient: patientReducer,
    payment: paymentReducer,
    antecedent: antecedentReducer,
    cita: citaReducer,
    visita: visitaReducer,
    receta: recetaReducer,
    estudio: estudioReducer,
    notification: notificationReducer,
    configuration: configurationReducer,
  },
});

setupListeners(store.dispatch);