import { useEffect, useState } from "react";
import { Button, Input, Textarea } from "../../components/Form";
import TwoColumRow from "../../components/TwoColumRow";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createEstudios, getEstudiosByCitaId, updateEstudios } from "../../redux/features/estudiosSlice";
import toast from "react-hot-toast";
import { FileIcon } from "../../components/FileIcon";
import { FaTimes } from "react-icons/fa";
import MultipleUploader from "../../components/MultipleUploader";

const AnalisisGabinete = ({ data, onClose }) => {
  const [archivos, setArchivos] = useState(null);
  const [files, setFiles] = useState([]);

  // const [value, setValue] = useState(false);
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    getValues: getFormValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (data) {
      // Iterar sobre las claves del objeto data
      Object.keys(data).forEach((key) => {
        // Comprobar si la clave existe en el formulario
        if (register(key)) {
          // Establecer el valor del campo en el formulario
          setFormValue(key, data[key]);
        }
      });

      setArchivos(data.archivos);
    }
  }, [data, register, setFormValue]);

  const dispatch = useDispatch();

  const onSubmit = async (body) => {
    body.cita_id = data?.cita_id;
    body.tipo = data?.tipo;
    body.archivos = archivos;
    body.files = files;
    let res = null;
    if(data?.id){
      body.id = data?.id;
      res = await dispatch(updateEstudios(body));
    } else {
      res = await dispatch(createEstudios(body));
    }
    
    if (res?.error) {
      toast.error('No se pudo guardar la orden: '+ res.error)
    } else {
      setFiles([]);
      toast.success("Orden Guardada con exito!");
      dispatch(getEstudiosByCitaId(data.cita_id));
      onClose();
    }
  }

  const deleteFile = (index) => {
    setFiles(files.filter((file, i) => index !== i));
  };

  return (
    <>
      <div className=" h-96 overflow-y-scroll border px-5 mb-5 py-5 rounded-lg">
        <TwoColumRow>
          <Input
            label="Nombre"
            color={true}
            register={register("nombre", {
              required: "Este campo es requerido.",
            })}
            error={errors?.nombre_otro}
          />
          <Input
            label="Resultado"
            color={true}
            register={register("resultado")}
          />
        </TwoColumRow>
        <Textarea
          register={register("descripcion")}
          name="descripcion"
              setValue={setFormValue}
              getValue={getFormValue}
          label="Descripción"
          color={true}
        />
      </div>
      {archivos?.length > 0 && (
        <div>
          <h2>Archivos adjuntos</h2>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Nombre
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Archivo
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {archivos.split(",").map((file, index) => (
                <tr key={index}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {file.split("/")?.at(-1)}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <a
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-subMain"
                    >
                      <span className="mr-2 inline-block">
                        <FileIcon extension={file.split(".").pop()} />
                      </span>
                      Descargar
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex gap-3 flex-col w-full col-span-6 my-2">
        <p className="text-sm">Archivos Adjuntos</p>
        <div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
          {files.length > 0 &&
            files.map((file, i) => (
              <div className="relative w-full">
                <img
                  src={file?.preview}
                  alt={file.name}
                  className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
                  key={i}
                />
                <button
                  onClick={() => deleteFile(i)}
                  className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
                >
                  <FaTimes className="text-red-500" />
                </button>
              </div>
            ))}
        </div>

        <MultipleUploader
          setFile={setFiles}
          file={files}
          title="Arrastre sus archivos aqui"
          placeholder="Solo se aceptan archivos tipo imagen y documento"
        />
      </div>
      <Button label="Guardar" onClick={handleSubmit(onSubmit)} />
    </>
  );
};
export default AnalisisGabinete;
