import React from "react";
import { MenuDatas } from "../components/Datas";
import { Link, useParams } from "react-router-dom";
import { MdOutlinePanoramaPhotosphereSelect } from "react-icons/md";
import { TbChartHistogram } from "react-icons/tb";
import { BiCalendar } from "react-icons/bi";
import {
  RiHeartLine,
  RiMoneyDollarCircleLine,
  RiUserLine,
} from "react-icons/ri";
import { GiChemicalDrop } from "react-icons/gi";

export const patientTab = [
  {
    id: 0,
    title: "Vista 360°",
    path: "/patients/preview/",
    icon: MdOutlinePanoramaPhotosphereSelect,
  },
  {
    id: 1,
    title: "Agenda Paciente",
    path: "/patients/agenda/",
    icon: BiCalendar,
  },
  {
    id: 2,
    title: "Informacion del paciente",
    path: "/patients/patient_info/",
    icon: RiUserLine,
  },
  {
    id: 3,
    title: "Antecedentes Clinicos",
    path: "/patients/health_info/",
    icon: RiHeartLine,
  },
  {
    id: 4,
    title: "Historial Medico",
    path: "/patients/history/",
    icon: TbChartHistogram,
  },
  {
    id: 5,
    title: 'Análisis y Laboratorios',
    path: '/patients/laboratories/',
    icon: GiChemicalDrop,
  },
  {
    id: 6,
    title: "Pagos",
    path: "/patients/payments/",
    icon: RiMoneyDollarCircleLine,
  },
];
function PatientSidebar() {
  // active link
  const currentPath = (path) => {
    const currentPath =
      window.location.pathname.split("/")[1] === path.split("/")[1];
    if (currentPath) {
      return path;
    }
    return null;
  };
  const currentPathP = (path) => {
    const currentPath =
      window.location.pathname.split("/")[1] === path.split("/")[1];
    const currentPathP = window.location.pathname.split("/")[2] === path.split("/")[2];
    if (currentPath && currentPathP) {
      return path;
    }
    return null;
  };

  const {id} = useParams();

  return (
    <div className="bg-white xl:shadow-lg py-6 px-4 xl:h-screen w-full border-r border-border h-full overflow-y-scroll scrollbar-none">
      <Link to="/" key="homepage">
        <img
          src="/images/logo.png"
          alt="logo"
          className="w-full h-32 object-contain"
        />
      </Link>
      <div className="flex-col gap-2 my-12">
        {MenuDatas.map((item, index) => (
          <div>
            <Link
              to={item.path}
              key={item.path}
              className={`
              ${currentPath(item.path) === item.path ? "bg-text" : ""}
              flex gap-4 transitions group items-center justify-start w-full p-4 rounded-lg hover:bg-text my-1`}
            >
              <item.icon className={`text-xl text-subMain`} />
              <p
                className={`text-sm font-medium group-hover:text-subMain ${
                  currentPath(item.path) === item.path
                    ? "text-subMain"
                    : "text-gray-500"
                }`}
              >
                {item.title}
              </p>
            </Link>
            {index === 1 &&
              patientTab.map((itemP, indexP) => (
                <Link
                  to={itemP.path + "" + id}
                  key={itemP.id}
                  className={`
                  ${currentPathP(itemP.path) === itemP.path ? "bg-text" : ""}
                  flex gap-4 transitions group pl-9 w-full p-4 rounded-lg hover:bg-text my-1`}
                >
                  <itemP.icon className={`text-xl text-subMain`} />
                  <p
                    className={`text-sm font-medium group-hover:text-subMain ${
                      currentPathP(itemP.path) === itemP.path
                        ? "text-subMain"
                        : "text-gray-500"
                    }`}
                  >
                    {itemP.title}
                  </p>
                </Link>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default PatientSidebar;
