import { useQuery } from "@tanstack/react-query";
import api from "./api";

const getPatientById = async ({ queryKey }) => {
  const [_, id] = queryKey;
  if (!id) return;
  const { data } = await api.get(`/api/v1/patient/${id}`);
  return data;
};

export const usePatient = (id) => {
  return useQuery({ queryKey: ["patient", id], queryFn: getPatientById });
};
