import React from 'react';
import Drawer from 'react-modern-drawer';

function MainPatientDrawer({ isOpen, toggleDrawer, children }) {
  return (
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction="left"
      className="bg-white"
    >
      {children}
    </Drawer>
  );
}

export default MainPatientDrawer;
