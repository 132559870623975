import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { FromToDate } from "../../components/Form";
import { Transactiontable } from "../../components/Tables";
import { sortsDatas, transactionData } from "../../components/Datas";
import { BiPlus, BiTime } from "react-icons/bi";
import { MdEditCalendar, MdOutlineCalendarMonth } from "react-icons/md";
import { toast } from "react-hot-toast";
import { BsCalendarMonth } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePayment,
  getAllPaymentStatistics,
  getAllPayments,
  getPaymentError,
  getPaymentStatistics,
  getPaymentStatus,
  getPayments,
} from "../../redux/features/paymentSlice";
import ConfirmationAlert from "../../components/ConfirmationAlert";

function Payments() {
  const [status, setStatus] = useState(sortsDatas.status[0]);
  const [method, setMethod] = useState(sortsDatas.method[0]);
  const [filtro, setFiltro] = useState("");
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [dateRange, setDateRange] = useState([
    new Date("01/01/2024"),
    new Date(),
  ]);
  const [startDate, endDate] = dateRange;
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const paymentStatistics = useSelector(getAllPaymentStatistics);
  const paymentStatus = useSelector(getPaymentStatus);
  const payments = useSelector(getAllPayments);
  const error = useSelector(getPaymentError);

  useEffect(() => {
    dispatch(getPaymentStatistics());
    dispatch(getPayments());
    setFilteredPayments(payments);
  }, [payments?.length, paymentStatistics?.total]);

  const sorts = [
    {
      id: 2,
      selected: status,
      setSelected: setStatus,
      datas: sortsDatas.status,
    },
    {
      id: 3,
      selected: method,
      setSelected: setMethod,
      datas: sortsDatas.method,
    },
  ];
  // boxes
  const boxes = [
    {
      id: 1,
      title: "Pagos del día",
      value: "day",
      color: ["bg-subMain", "text-subMain"],
      icon: BiTime,
    },
    {
      id: 2,
      title: "Pagos del mes actual",
      value: "month",
      color: ["bg-orange-500", "text-orange-500"],
      icon: BsCalendarMonth,
    },
    {
      id: 3,
      title: "Pagos del año actual.",
      value: "year",
      color: ["bg-green-500", "text-green-500"],
      icon: MdOutlineCalendarMonth,
    },
    {
      id: 4,
      title: "Pagos Total",
      value: "total",
      color: ["bg-blue-500", "text-blue-500"],
      icon: MdEditCalendar,
    },
  ];

  const editPayment = (id) => {
    navigate(`/payments/edit/${id}`);
  };
  // preview
  const previewPayment = (id) => {
    navigate(`/payments/preview/${id}`);
  };

  const bajaPayment = async (id) => {
    ConfirmationAlert({
      title: "Dar baja pago",
      text: "Esta seguro de dar de baja el registro de pago?",
      onAccept: async () => {
        const res = await dispatch(deletePayment(id));
        if (res.error) {
          toast.error(res.error.message, { duration: 5000 });
        } else {
          toast.success("Pago dado de baja con exito!", { duration: 5000 });
          navigate(0);
        }
      },
    });
  };

  const handleFilter = (e) => {
    setFiltro(e.target.value.toLowerCase());
    if (e.target.value === "") {
      setFilteredPayments(payments);
    } else {
      setFilteredPayments(
        payments.filter(
          (payment) =>
            payment?.patient?.persona?.nombre?.toLowerCase().includes(filtro) ||
            payment?.patient?.persona?.apellidos?.toLowerCase().includes(filtro)
        )
      );
    }
  };

  return (
    <Layout>
      <Link
        to="/payments/create"
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </Link>

      <h1 className="text-xl font-semibold">Pagos</h1>
      {/* boxes */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mt-8">
        {boxes.map((box, index) => (
          <div
            key={box.id}
            className="bg-white flex-btn gap-4 rounded-xl border-[1px] border-border p-5"
          >
            <div className="w-3/4">
              <h2 className="text-sm font-medium">{box.title}</h2>
              <h2 className="text-xl my-6 font-medium">
                {paymentStatistics !== null
                  ? paymentStatistics[Object.keys(paymentStatistics)[index]] +
                    " bs."
                  : ""}
              </h2>
            </div>
            <div
              className={`w-10 h-10 flex-colo rounded-md text-white text-md ${box.color[0]}`}
            >
              <box.icon />
            </div>
          </div>
        ))}
      </div>
      {/* datas */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 xs:grid-cols-1 md:grid-cols-2 gap-2">
          <input
            type="text"
            value={filtro}
            onChange={(e) => handleFilter(e)}
            placeholder="Filtrar por Nombre o Apellidos"
            className="h-14 text-sm text-main rounded-md bg-dry border border-border px-4"
          />

          <FromToDate
            startDate={startDate}
            endDate={endDate}
            bg="bg-dry"
            onChange={(update) => {
              setDateRange(update);
              setFilteredPayments(
                payments.filter(
                  (payment) =>
                    new Date(payment.created_at) >=
                      update[0]?.setHours(0, 0, 0, 0) &&
                    new Date(payment.created_at) <=
                      update[1]?.setHours(23, 59, 59, 999)
                )
              );
            }}
          />
        </div>
        <div className="mt-8 w-full overflow-x-scroll">
          <Transactiontable
            status={paymentStatus}
            data={filteredPayments}
            action={true}
            functions={{
              edit: editPayment,
              preview: previewPayment,
              delete: bajaPayment,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Payments;
