import React, { useEffect } from 'react';
import { Button } from '../../components/Form';
import { BiPlus } from 'react-icons/bi';
import { FiEye } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { medicalRecodData } from '../../components/Datas';
import MedicalRecodModal from '../../components/Modals/MedicalRecodModal';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../../components/Modals/Modal';
import NewMedicalRecode from './NewMedicalRecode';
import { useDispatch, useSelector } from 'react-redux';
import { getAllVisitas, getVisitas, resetVisita } from '../../redux/features/visitasSlice';
import { getCitas, getCitasByPatientId } from '../../redux/features/citasSlice';

function MedicalRecord() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [datas, setDatas] = React.useState({});
  const navigate = useNavigate();

  const {id} = useParams();
  const dispatch = useDispatch();
  const visitas = useSelector(getAllVisitas);
  const citas = useSelector(getAllVisitas);
  useEffect(() => {
    dispatch(getVisitas(id));
    dispatch(getCitas())
  }, [visitas?.length])


  return (
    <>
      {
        // Modal
        isOpen && (
          <Modal
            closeModal={() => {
              setIsOpen(false);
              setDatas({});
            }}
            isOpen={isOpen}
            title="Historia de Atenciones"
          >
            <NewMedicalRecode cita={{patient: citas?.find(cita => cita.cita_id === datas?.cita?.id)?.patient, ...datas?.cita}} />
          </Modal>
        )
      }
      <div className="flex flex-col gap-6">
        <div className="flex-btn gap-4">
          <h1 className="text-sm font-medium sm:block hidden">
            Historial Medico - Registros({visitas?.length ?? 0})
          </h1>
          <div className="sm:w-1/4 w-full">
          </div>
        </div>
        {visitas?.map((data) => (
          data.cita.tipo_evento !== "Analisis o Laboratorio" &&
          <div
            key={data.id}
            className="bg-dry items-start grid grid-cols-12 gap-4 rounded-xl border-[1px] border-border p-6"
          >
            <div className="col-span-12 md:col-span-2">
              <p className="text-xs text-textGray font-medium">{new Date(data.cita.fecha).toLocaleDateString()}</p>
            </div>
            <div className="col-span-12 md:col-span-6 flex flex-col gap-2">
              
                <p className="text-xs text-main font-light">
                  <span className="font-medium">Motivo:</span>{' '}
                  {
                    data.motivo?.length > 40
                      ? `${data.motivo.slice(0, 40)}...`
                      : data.motivo
                  }
                </p>
                <p className="text-xs text-main font-light">
                  <span className="font-medium">Diagnóstico:</span>{' '}
                  {
                    data.diagnostico?.length > 40
                      ? `${data.diagnostico?.replace('|', ', ').slice(0, 40)}...`
                      : data.diagnostico?.replace('|', ', ')
                  }
                </p>
                <p className="text-xs text-main font-light">
                  <span className="font-medium">Comentarios:</span>{' '}
                  {
                    data.comentarios?.length > 40
                      ? `${data.comentarios?.slice(0, 40)}...`
                      : data.comentarios
                  }
                </p>
                <p className="text-xs text-main font-light">
                  <span className="font-medium">Conducta:</span>{' '}
                  {
                    data.conducta?.length > 40
                      ? `${data.conducta?.slice(0, 40)}...`
                      : data.conducta
                  }
                </p>
            </div>
            {/* price */}
            <div className="col-span-12 md:col-span-2">
              <p className="text-xs text-subMain font-semibold">
                <span className="font-light text-main">{data?.cita?.tipo_evento}</span>{' '}
              </p>
            </div>
            {/* actions */}
            <div className="col-span-12 md:col-span-2 flex-rows gap-2">
              <button
                onClick={() => {
                  setIsOpen(true);
                  setDatas(data);
                  dispatch(resetVisita());
                }}
                className="text-sm flex-colo bg-white text-subMain border border-border rounded-md w-2/4 md:w-10 h-10"
              >
                <FiEye />
              </button>
              {/* <button
                onClick={() => {
                  toast.error('No disponible');
                }}
                className="text-sm flex-colo bg-white text-red-600 border border-border rounded-md w-2/4 md:w-10 h-10"
              >
                <RiDeleteBin6Line />
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default MedicalRecord;
