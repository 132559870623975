import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "http://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf" },
    {
      src: "http://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf",
      fontStyle: "normal",
      fontWeight: 700,
    },
  ],
});

// Definir los estilos
const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    color: "#454546",
    height: "100%",
  },
  header: {
    paddingHorizontal: 40,
    paddingTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: "auto",
    height: 75,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#000",
    marginBottom: 20,
  },
  section: {
    paddingHorizontal: 40,
    marginBottom: 10,
  },
  textBold: {
    color: "#000",
    fontSize: 12,
    fontFamily: "Roboto",
  },
  text: {
    marginBottom: 5,
    fontSize: 12,
    fontFamily: "Roboto",
  },
  footerImage: {
    width: "100%",
    height: "8%",
    position: "absolute",
    bottom: 0,
  },
  watermark: {
    position: "absolute",
    top: "35%",
    left: "15%",
    width: "70%",
    opacity: 0.2,
    zIndex: "-10",
  },
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 50,
    marginBottom: 20,
  },
  gridItem: {
    width: "48%", // two items per row, with some space between
    marginBottom: 5,
  },
  singleItem: {
    width: "100%",
  },
  information: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 40,
    marginBottom: 10,
  },
});

const OrdenPdf = ({ data }) => {
  const orden = [];
  if (data.orden.tipo !== "Analisis de Gabinete")
    Object.keys(data.orden).forEach((key) => {
      if (
        data?.orden[key] &&
        !key.includes("resultado") &&
        !key.includes("_at") &&
        key !== "nombre" &&
        key !== "tipo" &&
        key !== "id" &&
        key !== "cita_id"
      ) {
        orden.push(key.replaceAll("_", " ").toUpperCase());
      }
    });

  return (
    <Document>
      <Page style={styles.page}>
        <Image style={styles.watermark} src="/images/longLogo.png" />
        {/* Header */}
        <View style={styles.header}>
          <Image style={styles.logo} src="/images/smallLogo.png" />
        </View>

        {/* Title */}
        <Text style={styles.title}>ORDEN DE ESTUDIOS</Text>

        {/* Patient Information */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.text}>
              <Text style={styles.textBold}>Nombre:</Text> {data?.nombre}
            </Text>
            {/* <Text style={styles.text}>
              <Text style={styles.textBold}>Edad:</Text> {data?.edad}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.textBold}>Lugar de atención:</Text>{" "}
              {data?.lugarAtencion}
            </Text> */}
          </View>
          <View style={styles.gridItem}>
            <Text style={styles.text}>
              <Text style={styles.textBold}>Fecha:</Text> {data?.fecha}
            </Text>
            {/* <Text style={styles.text}>
              <Text style={styles.textBold}>Seguro:</Text> {data?.seguro}
            </Text> */}
            {data?.orden?.tipo && (
              <Text style={styles.text}>
                <Text style={styles.textBold}>Tipo:</Text> {data?.orden?.tipo}
              </Text>
            )}
          </View>
        </View>

        {/* Receta Details */}
        <View style={styles.section}>
          <Text style={styles.text}>
            <Text style={styles.textBold}></Text>
          </Text>

          {orden.length > 0 ? (
            orden.map((val, index) => (
              <View style={styles.section} key={val}>
                <Text style={styles.text}>{`${index + 1}). ${val}`}</Text>
              </View>
            ))
          ) : (
            <View style={styles.section}>
              <Text style={styles.text}>
                <Text style={styles.textBold}>Nombre:</Text>{" "}
                {data?.orden?.nombre}
              </Text>
              <Text style={styles.text}>
                <Text style={styles.textBold}>Descripción:</Text>{" "}
                {data?.orden?.descripcion}
              </Text>
            </View>
          )}
        </View>

        {/* Footer */}
        <Image style={styles.footerImage} src="/images/pdfFooter.png" />
      </Page>
    </Document>
  );
};

export default OrdenPdf;
