import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getVisitas = createAsyncThunk("visitas/getVisita", async (id) => {
  const { data } = await axios.get(`${apiUrl}/api/v1/visitas/patient/${id}`);
  
  return data;
});

export const getVisitasByCitaId = createAsyncThunk(
  "visitas/getVisitaByCitaId",
  async (idCita) => {
    const { data } = await axios.get(`${apiUrl}/api/v1/visitas/${idCita}`);
    return data;
  }
);

export const updateVisitasById = createAsyncThunk(
  "visitas/updateVisitasById",
  async (body) => {
    
    try {
      const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }

    const response = await axios.post(
      `${apiUrl}/api/v1/visitas/${body.id}`,
      fd,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

      return response.data;
    } catch (error) {
      console.error("Error al actualizar la visita:", error.response?.data || error.message);
      throw error.response?.data || error;
    }
  }
);


export const createVisita = createAsyncThunk(
  "visitas/createVisita",
  async (body) => {
    const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const { data } = await axios.post(
      `${apiUrl}/api/v1/visitas`,
      fd
    );
    return data;
  }
);

const initialState = {
  status: "idle",
  visitas: null,
  visita: null,
  error: null,
};

export const visitaSlice = createSlice({
  name: "visita",
  initialState,
  reducers: {
    resetVisita(state) {
      state.visita = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getVisitasByCitaId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getVisitasByCitaId.fulfilled, (state, action) => {
        state.status = "succeed";
        state.visita = action.payload;
      })
      .addCase(getVisitasByCitaId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getVisitas.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getVisitas.fulfilled, (state, action) => {
        state.status = "succeed";
        state.visitas = action.payload;
      })
      .addCase(getVisitas.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createVisita.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createVisita.fulfilled, (state, action) => {
        state.status = "succeed";
        state.visita = action.payload;
      })
      .addCase(createVisita.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateVisitasById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateVisitasById.fulfilled, (state, action) => {
        state.status = "succeed";
        state.visita = action.payload;
      })
      .addCase(updateVisitasById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { resetVisita } = visitaSlice.actions;

export default visitaSlice.reducer;

export const getAllVisitas = (state) => state.visita.visitas;
export const getVisita = (state) => state.visita.visita;
export const getVisitasStatus = (state) => state.visita.status;
export const getVisitasError = (state) => state.visita.error;
