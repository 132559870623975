import React, { useState } from "react";
import Modal from "./Modal";
import { memberData } from "../Datas";
import { RadioGroup } from "@headlessui/react";
import { useDispatch } from "react-redux";
import { addEstudio } from "../../redux/features/estudiosSlice";

function LaboratoryServiceModal({ closeModal, isOpen, patient, visita }) {
  const [selected, setSelected] = useState(memberData[0]);

  const dispatch = useDispatch();

  const datas = [
    {
      id: 1,
      name: "Laboratorio",
    },
    {
      id: 2,
      name: "Analisis de Gabinete",
    },
  ];

  const handleAdd = (name) => {
    dispatch(addEstudio({ cita_id: visita?.cita_id, tipo: name }));
    closeModal();
  };

  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      title={patient ? "Patients" : "Análisis y Laboratorios"}
      width={"max-w-xl"}
      heigh={"max-h-lg"}
    >
      <div className="flex-colo gap-6">
        {/* data */}
        <div className="w-full max-h-[500px] overflow-y-scroll">
          <RadioGroup value={selected} onChange={setSelected}>
            <div className="space-y-2">
              {datas.map((user) => (
                <RadioGroup.Option
                  key={user.id}
                  value={user}
                  className={({ active, checked }) =>
                    `
                    ${active ? "border-subMain bg-subMain text-white" : ""}
                    rounded-xl border-[1px] border-border p-4 group hover:bg-subMain hover:text-white`
                  }
                  onClick={() => handleAdd(user.name)}
                >
                  <>
                    <h6 className="text-sm">{user.name}</h6>
                  </>
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
        {/* button */}
        {/* <Button onClick={handleAdd} label="Agregar" Icon={BiPlus} /> */}
      </div>
    </Modal>
  );
}

export default LaboratoryServiceModal;
