import { toast } from "react-hot-toast";
import { BiCalendar } from "react-icons/bi"; // Importa el icono necesario aquí

// Función para mostrar un toast personalizado
export const showCustomToast = (newNotification) => {
  toast.custom((t) => (
    <div
      className={`max-w-md w-[300px] bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 animate-enter z-[100]`}
    >
      <div className="flex-1 w-0 p-2">
        <div className="flex items-start">
          <div className="flex-shrink-0 pt-0.5">
            <div
              className="bg-subMain text-white w-8 h-8 rounded-full text-md flex-colo border-[.5px] border-subMain"
            >
              <BiCalendar />
            </div>
          </div>
          <div className="ml-3 flex-1">
            <p className="text-xs font-medium text-gray-900">
              {newNotification.patient.persona.nombre +
                " " +
                newNotification.patient.persona.apellidos}
            </p>
            <p className="mt-0.5 text-xs text-gray-500">
              {`${newNotification.motivo}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  ), { position: "top-center"});
};


