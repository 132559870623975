import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getEstudios = createAsyncThunk(
  "estudios/getEstudios",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/estudios`);
    return data;
  }
);

export const getEstudiosParaGrafico = createAsyncThunk(
  "estudios/getEstudiosParaGrafico",
  async (id) => {
    const { data } = await axios.get(
      `${apiUrl}/api/v1/estudios/graficos/${id}`
    );
    return data;
  }
);

export const getEstudiosByCitaId = createAsyncThunk(
  "estudios/getEstudiosByCitaId",
  async (idCita) => {
    const { data } = await axios.get(
      `${apiUrl}/api/v1/estudios/cita/${idCita}`
    );
    return data;
  }
);
export const getEstudiosByPatientId = createAsyncThunk(
  "estudios/getEstudiosByPatientId",
  async (idPatient) => {
    const { data } = await axios.get(
      `${apiUrl}/api/v1/estudios/paciente/${idPatient}`
    );
    return data;
  }
);

export const updateEstudios = createAsyncThunk(
  "estudios/updateEstudios",
  async (body) => {
    const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const data = await axios.post(`${apiUrl}/api/v1/estudios/${body.id}`, fd);
    return data;
  }
);

export const createEstudios = createAsyncThunk(
  "estudios/createEstudios",
  async (body) => {
    const fd = new FormData();
    if (body.files) {
      for (let i in body.files) {
        fd.append(`file_${i}`, body.files[i], body.files[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    const { data } = await axios.post(`${apiUrl}/api/v1/estudios`, fd);
    return data;
  }
);

export const deleteEstudio = createAsyncThunk(
  "estudios/deleteEstudio",
  async (body) => {
    const { data } = await axios.delete(
      `${apiUrl}/api/v1/estudios/${encodeURIComponent(body.tipo)}/${body.id}`
    );
    return data;
  }
);

const initialState = {
  status: "idle",
  estudios: [],
  estudiosCita: [],
  graficos: null,
  estudiosPatient: null,
  error: null,
};

export const estudioSlice = createSlice({
  name: "estudio",
  initialState,
  reducers: {
    addEstudio: (state, action) => {
      const { cita_id, tipo } = action.payload;
      const newEstudio = { tipo, cita_id };
      if (state.estudiosCita) {
        state.estudiosCita.push(newEstudio);
      } else {
        state.estudiosCita = [newEstudio];
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getEstudios.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEstudios.fulfilled, (state, action) => {
        state.status = "succeed";
        state.estudios = action.payload;
      })
      .addCase(getEstudios.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getEstudiosParaGrafico.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEstudiosParaGrafico.fulfilled, (state, action) => {
        state.status = "succeed";
        state.graficos = action.payload;
      })
      .addCase(getEstudiosParaGrafico.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getEstudiosByCitaId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEstudiosByCitaId.fulfilled, (state, action) => {
        state.status = "succeed";
        state.estudiosCita = action.payload;
      })
      .addCase(getEstudiosByCitaId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getEstudiosByPatientId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEstudiosByPatientId.fulfilled, (state, action) => {
        state.status = "succeed";
        state.estudiosPatient = action.payload;
      })
      .addCase(getEstudiosByPatientId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(updateEstudios.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateEstudios.fulfilled, (state, action) => {
        state.status = "succeed";
        //todo agregar estudio actualizado a lista
      })
      .addCase(updateEstudios.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(createEstudios.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createEstudios.fulfilled, (state, action) => {
        state.status = "succeed";
        //todo agregar estudio creado a lista
      })
      .addCase(createEstudios.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      })
      .addCase(deleteEstudio.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteEstudio.fulfilled, (state, action) => {
        state.status = "succeed";
      })
      .addCase(deleteEstudio.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error;
      });
  },
});

export const { addEstudio } = estudioSlice.actions;

export default estudioSlice.reducer;

export const getAllEstudios = (state) => state.estudio.estudios;
export const getAllEstudiosGraficos = (state) => state.estudio.graficos;
export const getAllEstudiosByCita = (state) => state.estudio.estudiosCita;
export const getAllEstudiosByPatient = (state) => state.estudio.estudiosPatient;
export const getEstudiosStatus = (state) => state.estudio.status;
export const getEstudiosError = (state) => state.estudio.error;
