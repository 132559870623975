import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { BiChevronDown, BiPlus } from "react-icons/bi";
import PatientMedicineServiceModal from "./PatientMedicineServiceModal";
import { Button, Input, Select, Textarea } from "../Form";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
	getAllMedicamentos,
	getMedicamentos,
} from "../../redux/features/recetaSlice";

const presentaciones = [
	{ id: 0, name: "Ninguno" },
	{ id: 1, name: "Crema" },
	{ id: 2, name: "Cremas" },
	{ id: 3, name: "Gel" },
	{ id: 4, name: "Geles" },
	{ id: 5, name: "Suero" },
	{ id: 6, name: "Sueros" },
	{ id: 7, name: "Loción" },
	{ id: 8, name: "Lociones" },
	{ id: 9, name: "Champú" },
	{ id: 10, name: "Champús" },
	{ id: 11, name: "Tableta" },
	{ id: 12, name: "Tabletas" },
	{ id: 13, name: "Cápsula" },
	{ id: 14, name: "Cápsulas" },
	{ id: 15, name: "Comprimido" },
	{ id: 16, name: "Comprimidos" },
	{ id: 17, name: "Jarabe" },
	{ id: 18, name: "Jarabes" },
	{ id: 19, name: "Gotas" },
	{ id: 20, name: "Frasco" },
	{ id: 21, name: "Frascos" },
	{ id: 22, name: "Tubo" },
	{ id: 23, name: "Tubos" },
];

function MedicineDosageModal({ closeModal, isOpen, setReceta }) {
	const [open, setOpen] = useState(false);
	const [instraction, setInstraction] = useState({ name: "Ninguno" });
	const [presentacion, setPresentacion] = useState(presentaciones[0]);
	const [cantidad, setCantidad] = useState("");
	const [filteredData, setFilteredData] = useState([]);

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			nombre: "",
			cantidad: "",
		},
	});
	const dispatch = useDispatch();
	const medicamentos = useSelector(getAllMedicamentos);

	useEffect(() => {
		setInstraction(watch("instraction"));
		dispatch(getMedicamentos());
	}, [dispatch, medicamentos.length]);

	const handleAgregar = (body) => {
		setReceta((receta) => [
			...receta,
			{
				nombre: body.nombre,
				instrucciones: body.instraction,
				presentacion: presentacion.name,
				cantidad: body.cantidad,
			},
		]);
		closeModal();
	};
	const handleChange = (e) => {
		let filtered = medicamentos.filter((item) =>
			item.name.toLowerCase().includes(e.target.value.toLowerCase())
		);
		if (filtered.length > 10) filtered = filtered.slice(0, 10);

		if (e.target.value.length === 0) {
			setFilteredData(medicamentos.slice(0, 10));
		} else setFilteredData(filtered);
	};
	return (
		<>
			{open && (
				<PatientMedicineServiceModal
					closeModal={() => setOpen(!open)}
					isOpen={open}
					patient={false}
				/>
			)}
			<Modal
				closeModal={closeModal}
				isOpen={isOpen}
				title="Agregar Medicamento a Prescripción"
				width={"max-w-xl"}
			>
				<div className="flex-colo gap-6">
					{/* title */}
					<div className="w-full">
						<Input
							label="Medicamento"
							color={true}
							register={register("nombre", {
								required: "Introduzca el nombre.",
								onChange: (e) => handleChange(e),
								onBlur: () => setFilteredData([]),
								onMouseDown: (e) => handleChange(e),
							})}
							type={"text"}
							error={errors.nombre}
						/>

						{filteredData?.length > 0 && (
							<ul className="max-h-40 w-full sm:w-1/2 border absolute  rounded-lg z-50 bg-white overflow-y-scroll">
								{filteredData?.map((item) => (
									<li
										key={item.id}
										onMouseDown={() => {
											setValue("nombre", item.name);
											setTimeout(setFilteredData([]), 400);
										}}
										className="border-b py-2 px-5"
									>
										{`${item.name}`}
									</li>
								))}
							</ul>
						)}
					</div>

					<div className="flex w-full flex-col gap-3">
						<p className="text-black text-sm">Instrucciones</p>
						<Textarea
							color={true}
							rows={3}
							visible={true}
							placeholder={"Cada 8 horas..."}
							name="instraction"
							register={register("instraction", {
								required: "Escribe las instrucciones.",
							})}
						/>
						{errors.instraction && (
							<p className="text-red-500 text-sm">
								{errors.instraction.message}
							</p>
						)}
					</div>
					<div className="flex w-full flex-col gap-3">
						<p className="text-black text-sm">Presentación</p>
						<Select
							selectedPerson={presentacion}
							setSelectedPerson={setPresentacion}
							datas={presentaciones}
						>
							<div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
								{presentacion.name} <BiChevronDown className="text-xl" />
							</div>
						</Select>
					</div>
					<Input
						label="Cantidad"
						color={true}
						register={register("cantidad", {
							required: "Introduzca la cantidad.",
							min: { value: 1, message: "La cantidad debe ser mayor a 0" },
						})}
						type={"number"}
						error={errors.cantidad}
					/>

					<Button
						onClick={handleSubmit(handleAgregar)}
						label="Agregar"
						Icon={BiPlus}
					/>
				</div>
			</Modal>
		</>
	);
}

export default MedicineDosageModal;