import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
	Button,
	DinamicInputWithSearchBar,
	Select,
	Textarea,
} from "../../components/Form";
import { getAge } from "../../utils/age";
import { BiChevronDown, BiPlus } from "react-icons/bi";
import { LaboratoryTable, MedicineDosageTable } from "../../components/Tables";
import { toast } from "react-hot-toast";
import MedicineDosageModal from "../../components/Modals/MedicineDosage";
import { FaFileDownload, FaTimes } from "react-icons/fa";
import { HiOutlineCheckCircle } from "react-icons/hi";
import LaboratoryServiceModal from "../../components/Modals/LaboratoryServiceModal";
import { useDispatch, useSelector } from "react-redux";
import {
	getVisita,
	getVisitasByCitaId,
	// createVisita,
	updateVisitasById,
} from "../../redux/features/visitasSlice";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import { Enfermedades } from "../../components/EnfermedadesCIE";
import {
	createReceta,
	deleteRecetaByVisitaId,
	getAllRecetas,
	getAllDocumentos,
	getRecetasById,
} from "../../redux/features/recetaSlice";
import { pdf } from "@react-pdf/renderer";
import RecetaPdf from "../../pdf/RecetaPdf";
import { saveAs } from "file-saver";
import {
	deleteEstudio,
	getAllEstudiosByCita,
	getEstudiosByCitaId,
} from "../../redux/features/estudiosSlice";
import OrdenPdf from "../../pdf/OrdenPdf";
import { RiDeleteBinLine } from "react-icons/ri";
import HistoriaPdf from "../../pdf/HistoriaPdf";
import MultipleUploader from "../../components/MultipleUploader";
import { FileIcon } from "../../components/FileIcon";
// import { useNavigate } from "react-router-dom";

const lugaresAtencion = [
	{
		id: 0,
		name: "Ninguno",
	},
	{
		id: 1,
		name: "Consultorio Privado",
	},
];

function NewMedicalRecode({ cita }) {

	const [archivos, setArchivos] = useState(null);
	const [files, setFiles] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [labOpen, setLabOpen] = useState(false);
	const [lugarAtencion, setLugarAtencion] = useState(lugaresAtencion[0]);
	const [diagnostico, setDiagnostico] = useState("");
	const [receta, setReceta] = useState([]);
	// const [motivo, setMotivo] = useState("");
	// const [valueIMC, setValueIMC] = useState("Ingrese el peso y la altura.");
	// const navigate = useNavigate();
	const dispatch = useDispatch();
	const visita = useSelector(getVisita);
	const recetasFromBack = useSelector(getAllRecetas);
	const documentosFromBack = useSelector(getAllDocumentos);
	const estudios = useSelector(getAllEstudiosByCita);

	//const {data: estudios, isLoading, error } = useEstudiosCita(cita?.id);

	const { register, handleSubmit, setValue, getValues } = useForm();

	useEffect(() => {
		if (cita) {
	
			dispatch(getVisitasByCitaId(cita.id));
			dispatch(getEstudiosByCitaId(cita.id));
	
			setValue("motivo", cita.motivo || "");
			setValue("tipo_evento", cita.tipo_evento || "");
			setValue("estado", cita.estado || "");
			setValue("fecha", cita.fecha || "");
			setValue("hora_inicio", cita.hora_inicio || "");
			setValue("hora_fin", cita.hora_fin || "");
			setValue("patient_id", cita.patient_id || "");
			setValue("cita_id", cita.id || "");
		}
	}, [cita, dispatch, setValue]);
	
	useEffect(() => {
		if (visita?.id) {
	
			dispatch(getRecetasById(visita.id));
	
			setValue("motivo", visita.motivo || "");
			setDiagnostico(visita.diagnostico || "");
			if (visita.lugar_atencion) {
				setLugarAtencion(
					lugaresAtencion.find((lugar) => lugar.name === visita.lugar_atencion)
				);
			}
			setValue("altura", visita.altura || "");
			setValue("conducta", visita.conducta || "");
			setValue("desglose_motivo", visita.desglose_motivo || "");
			setValue("examen_fisico", visita.examen_fisico || "");
			setValue("f_cardiaca", visita.f_cardiaca || "");
			setValue("f_respiratoria", visita.f_respiratoria || "");
			setValue("p_arterial_1", visita.p_arterial_1 || "");
			setValue("p_arterial_5", visita.p_arterial_5 || "");
			setValue("peso", visita.peso || "");
			setValue("temperatura", visita.temperatura || "");

			console.log("Archivos del paciente: ", cita?.archivos);
			console.log("Visita del paciente: ", cita);

			setArchivos(visita?.archivos || []);
		}
	}, [visita, dispatch, setValue]);

	const onSubmit = async (body) => {
		ConfirmationAlert({
			title: "Guardar Visita",
			text: `Estas seguro de guardar los datos de la visita?`,
			onAccept: async () => {
				console.log("onSubmit", body);
				body.patient_id = cita.patient_id;
				body.id = visita?.id;
				body.diagnostico = diagnostico;
				body.lugar_atencion = lugarAtencion?.name;
				body.files = files;
				body.archivos = archivos;
				/*if (!visita?.id) { */
				const newData = await dispatch(updateVisitasById(body));
				if (newData?.error) {
					toast.error(
						`No se pudo guardar los datos de la visita: ${newData.error}`,
						{ duration: 5000 }
					);
				} else {
					setFiles([]);
					console.log("Guardado exitoso!");
					
					toast.success("Datos de visita guardados con exito!", {
						duration: 5000,
					});
					window.location.reload();
					//navigate(0);
				}
				/* } else {
					body.id = visita.id;
					const newData = await dispatch(updateVisitasById(body));

					if(newData?.error) {
						toast.error(`No se pudo guardar los datos de la visita: ${newData.error}`)
					} else {
						setFiles([]);
						toast.success("Datos de visita guardados con exito!");
						navigate(`/patients/preview/${id}`);
            			window.location.reload();
					}
				} */
			},
		});
	};

	const deleteFile = (index) => {
		setFiles(files.filter((file, i) => index !== i));
	  };
	
	// const handleIMC = () => {
	//   const pesoF = getValues("peso");
	//   const alturaF = getValues("altura");
	//   if (pesoF > 0 && alturaF > 0) {
	//     const IMC = Number.parseFloat(
	//       pesoF / (((alturaF / 100) * alturaF) / 100)
	//     ).toFixed(2);
	//     if (IMC < 18.5) setValueIMC(`${IMC} - Bajo Peso`);
	//     else if (IMC < 25) setValueIMC(`${IMC} - Peso Saludable`);
	//     else if (IMC < 30) setValueIMC(`${IMC} - Sobrepeso`);
	//     else setValueIMC(`${IMC} - Obesidad`);
	//   } else {
	//     setValueIMC("Ingrese el peso y la altura.");
	//   }
	// };

	const handlePdfReceta = async () => {
		if (receta.length === 0 && recetasFromBack?.length === 0) {
			toast.error("Agregue elementos a la receta!", { duration: 5000 });
			return;
		}
		if (recetasFromBack?.length === 0) {
			ConfirmationAlert({
				title: "Guardar Receta",
				text: `Estas seguro de guardar la receta? Ya no podra editar despues la receta.`,
				onAccept: async () => {
					const datosReceta = {
						visita_id: visita?.id,
						recetas: JSON.stringify(receta),
						recetas_image: files,
						// Object.keys(files).map((key)=>{
						// 	return {
						// 		id: `file_${key}`,
						// 		file: files[key],
						// 		file_name: files[key].name
						// 	}
						// }),
					};

					// const recetas_docs = Object.keys(files).map((key) => ({
					// 	id: `file_${key}`,
					// 	file: files[key],
					// 	file_name: files[key].name
					// }))[0]; // Tomamos solo el primer elemento
					
					// const datosReceta = {
					// 	visita_id: visita?.id,
					// 	recetas: receta,
					// 	recetas_image: recetas_docs, // Ahora es un objeto, no un array
					// };
					
					// const formData = new FormData();
					// formData.append('visita_id', visita?.id);
					// formData.append('recetas', JSON.stringify(receta));

					// Object.keys(files).map((key)=>{
					// 	formData.append(`file_${key}`, files[key], files[key].name);
					// })

					console.log("Data: ", datosReceta);

					// for (let pair of formData.entries()) {
					// 	console.log(pair[0], pair[1]);
					// }
					setFiles([]);
					const newData = await dispatch(createReceta(datosReceta));
					if (newData?.error) {
						toast.error(`No se pudo generar la receta: ${newData.error}`, {
							duration: 5000,
						});
						return;
					} else {
						dispatch(getRecetasById(visita?.id));
						toast.success("Receta guardada con exito!", {
							duration: 5000,
						});
					}
				},
			});
		} else {
			const blob = await pdf(
				<RecetaPdf
					data={{
						nombre: `${cita?.patient?.persona?.nombre} ${cita?.patient?.persona?.apellidos}`,
						edad: `${
							cita?.patient?.persona?.fecha_nacimiento
								? getAge(new Date(cita?.patient?.persona?.fecha_nacimiento)) +
								  " años"
								: ""
						}`,
						fecha: new Date().toLocaleDateString("en-GB"),
						lugarAtencion:
							visita?.lugar_atencion ?? `${getValues("lugar_atencion")}`,
						seguro: `${cita?.patient?.empresa_seg ?? ""}`,
						recetas: recetasFromBack,
					}}
				/>
			).toBlob();
			saveAs(
				blob,
				`Receta-${visita.id}-${new Date().toLocaleDateString("en-GB")}.pdf`
			);
		}
	};

	const deleteReceta = () => {
		ConfirmationAlert({
			title: "Eliminar Receta",
			text: `Estas seguro de eliminar la receta? Ya no podra recuperar la informacion despues de eliminar la receta.`,
			onAccept: async () => {
				const newData = await dispatch(deleteRecetaByVisitaId(visita?.id));
				if (newData?.error) {
					toast.error(`No se pudo elimar la receta: ${newData.error}`, {
						duration: 5000,
					});
					return;
				} else {
					setReceta([]);
					setArchivos(null);
					setFiles([]);

					dispatch(getRecetasById(visita?.id));
					toast.success("Receta eliminada con exito!", {
						duration: 5000,
					});
				}
			},
		});
	};

	const handleImpresionIC = async () => {
		const blob = await pdf(
			<HistoriaPdf
				data={{
					patient: cita?.patient,
					visita,
					receta: recetasFromBack,
				}}
			/>
		).toBlob();
		saveAs(
			blob,
			`HC-${visita.id}-${new Date().toLocaleDateString("en-GB")}.pdf`
		);
	};
	return (
		<>
			{
				// modal
				isOpen && (
					<MedicineDosageModal
						isOpen={isOpen}
						setReceta={setReceta}
						closeModal={() => {
							setIsOpen(false);
						}}
					/>
				)
			}
			{
				// modal
				labOpen && (
					<LaboratoryServiceModal
						isOpen={labOpen}
						closeModal={() => {
							setLabOpen(!labOpen);
						}}
						visita={visita}
					/>
				)
			}
			<Tabs className="border-border">
				<TabList>
					{cita?.tipo_evento !== "Analisis o Laboratorio" && (
						<>
							<Tab>Atención</Tab>
							<Tab>Recetas</Tab>
						</>
					)}
					<Tab>Analisis y Laboratorios</Tab>
				</TabList>
				{cita?.tipo_evento !== "Analisis o Laboratorio" && (
					<>
						<TabPanel>
							{/* Atencion */}
							<div className=" grid grid-cols-12 gap-6 items-start">
								<div
									data-aos="fade-left"
									data-aos-duration="1000"
									data-aos-delay="100"
									data-aos-offset="200"
									className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
								>
									<div className="flex w-full flex-col gap-5">
										<Button label="Imprimir HC" onClick={handleImpresionIC} />
										<div className="flex w-full flex-col gap-2">
											<p className="text-black text-sm">Lugar de Atención</p>
											<Select
												selectedPerson={lugarAtencion}
												setSelectedPerson={setLugarAtencion}
												datas={lugaresAtencion}
											>
												<div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
													{lugarAtencion?.name}{" "}
													<BiChevronDown className="text-xl" />
												</div>
											</Select>
										</div>
										<Textarea
											label="Motivo de la consulta"
											color={true}
											rows={3}
											visible={true}
											placeholder={"Dolor..."}
											name="motivo"
											register={register("motivo")}
											setValue={setValue}
											getValue={getValues}
										/>
										<Textarea
											label="Enfermedad Actual"
											color={true}
											rows={3}
											register={register("desglose_motivo")}
											name="desglose_motivo"
											setValue={setValue}
											getValue={getValues}
											placeholder={"Dolor abdominal y ...."}
										/>
										{/* Vital Signs */}
										{/* <div className="border p-5 rounded-lg flex flex-col gap-3">
                      <h2 className="mb-3">Examen Físico General</h2>
                      <ThreeColumRow>
                        <Input
                          label="Frecuencia Cardiaca"
                          color={true}
                          type="number"
                          register={register("f_cardiaca")}
                          placeholder="0-200"
                        />
                        <Input
                          label="Presion Arterial 1'"
                          color={true}
                          type="text"
                          register={register("p_arterial_1")}
                          placeholder="40- 230/0-160"
                        />
                        <Input
                          label="Presion Arterial 5'"
                          color={true}
                          type="text"
                          register={register("p_arterial_5")}
                          placeholder="40- 230/0-160"
                        />
                      </ThreeColumRow>
                      <TwoColumRow>
                        <Input
                          label="Frecuencia Respiratoria"
                          color={true}
                          type="number"
                          register={register("f_respiratoria")}
                          placeholder="0-340"
                        />
                        <Input
                          label="Temperatura"
                          color={true}
                          type="number"
                          register={register("temperatura")}
                          placeholder="36"
                        />
                      </TwoColumRow>
                      <ThreeColumRow>
                        <Input
                          label="Peso"
                          color={true}
                          type="number"
                          register={register("peso", { onChange: handleIMC })}
                          placeholder="36 kg"
                        />
                        <Input
                          label="Altura"
                          color={true}
                          type="number"
                          register={register("altura", { onChange: handleIMC })}
                          placeholder="175cm"
                        />
                        <div className="flex flex-col justify-center items-center ">
                          <p>IMC</p>
                          <p className="p-4 font-medium">{valueIMC}</p>
                        </div>
                        p
                      </ThreeColumRow>
                    </div> */}
										<Textarea
											label="Descripción de la lesión"
											color={true}
											rows={3}
											register={register("examen_fisico")}
											name="examen_fisico"
											setValue={setValue}
											getValue={getValues}
											placeholder={"Otros datos del examen físico, ...."}
										/>
										<DinamicInputWithSearchBar
											label="Diagnóstico"
											color={true}
											rows={3}
											visible={true}
											datos={diagnostico}
											dataSearch={Enfermedades}
											setDatos={setDiagnostico}
											placeholder={"CIE-10..."}
										/>
										{/* <Textarea
											label="Comentarios"
											color={true}
											rows={3}
											register={register("comentarios")}
											name="comentarios"
											setValue={setValue}
											getValue={getValues}
											placeholder={"Comentarios...."}
										/> */}
										<Textarea
											label="Conducta"
											color={true}
											rows={3}
											register={register("conducta")}
											name="conducta"
											setValue={setValue}
											getValue={getValues}
											placeholder={"Conducta ...."}
										/>
										{ archivos?.length > 0 && (
											<div>
												<h2>Archivos adjuntos</h2>
												<table className="min-w-full divide-y divide-gray-200">
													<thead className="bg-gray-50">
													<tr>
														<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Nombre
														</th>
														<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
														Archivo
														</th>
													</tr>
													</thead>
													<tbody className="bg-white divide-y divide-gray-200">
													{archivos.split(",").map((file, index) => (
														<tr key={index}>
														<td className="px-6 py-4 whitespace-nowrap">
															<div className="text-sm text-gray-900">
															{file.split("/")?.at(-1)}
															</div>
														</td>
														<td className="px-6 py-4 whitespace-nowrap">
															<a
															href={file}
															target="_blank"
															rel="noopener noreferrer"
															className="text-subMain"
															>
															<span className="mr-2 inline-block">
																<FileIcon extension={file.split(".").pop()} />
															</span>
															Descargar
															</a>
														</td>
														</tr>
													))}
													</tbody>
												</table>
											</div>
										)}
										<div className="flex gap-3 flex-col w-full col-span-6">
											<p className="text-sm">Archivos Adjuntos</p>
											<div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
											{files.length > 0 &&
												files.map((file, i) => (
												<div className="relative w-full">
													<img
													src={file?.preview}
													alt={file.name}
													className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
													key={i}
													/>
													<button
													onClick={() => deleteFile(i)}
													className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
													>
													<FaTimes className="text-red-500" />
													</button>
												</div>
												))}
											</div>

											<MultipleUploader
												setFile={setFiles}
												file={files}
												title="Arrastre sus archivos aqui"
												placeholder="Solo se aceptan archivos tipo imagen y documento"
											/>
										</div>
										<Button
											label={"Guardar"}
											Icon={HiOutlineCheckCircle}
											onClick={handleSubmit(onSubmit)}
										/>
									</div>
								</div>
							</div>
						</TabPanel>
						<TabPanel>
							{/* receta */}
							<div className=" grid grid-cols-12 gap-6 items-start">
								<div
									data-aos="fade-left"
									data-aos-duration="1000"
									data-aos-delay="100"
									data-aos-offset="200"
									className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
								>
									<div>
										<div className="flex w-full flex-col gap-4 mb-6">
											<div className="flex justify-between items-center">
												<p className="text-black text-lg">Prescripción</p>
												<div className="flex gap-2">
													<button
														onClick={() => handlePdfReceta()}
														className="flex justify-center items-center bg-subMain rounded-lg text-white text-center text-2xl p-2"
													>
														<span className="text-sm mr-1">
															{recetasFromBack?.length > 0
																? "Imprimir "
																: "Guardar"}
														</span>{" "}
														<FaFileDownload />
													</button>
													{recetasFromBack?.length > 0 && (
														<button
															onClick={deleteReceta}
															className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
														>
															<RiDeleteBinLine />
														</button>
													)}
												</div>
											</div>
											<div className="w-full overflow-x-scroll">
												<MedicineDosageTable
													data={
														recetasFromBack?.length > 0
															? recetasFromBack
															: receta
													}
													functions={{
														delete: (id) => {
															setReceta((state) =>
																state.filter((_, index) => index !== id)
															);
															toast.success("Elemento eliminado!");
														},
													}}
													button={true}
												/>
											</div>
											{recetasFromBack?.length === 0 && (
												<button
													onClick={() => {
														setIsOpen(true);
													}}
													className=" text-subMain flex-rows gap-2 rounded-lg border border-subMain border-dashed py-4 w-full text-sm"
												>
													<BiPlus /> Agregar Medicamento
												</button>
											)}
											{
												documentosFromBack?.length > 0 ? (
													<div>
													<h2>Archivos adjuntos</h2>
													<table className="min-w-full divide-y divide-gray-200">
														<thead className="bg-gray-50">
															<tr>
																<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Nombre
																</th>
																<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
																Archivo
																</th>
															</tr>
														</thead>
														<tbody className="bg-white divide-y divide-gray-200">
														{
															documentosFromBack.map((documento, index) => (
																<tr key={index}>
																	<td className="px-6 py-4 whitespace-nowrap">
																		<div className="text-sm text-gray-900">
																		{documento.documento_nombre}
																		</div>
																	</td>
																	<td className="px-6 py-4 whitespace-nowrap">
																		<a
																		href={documento.documento_url}
																		target="_blank"
																		rel="noopener noreferrer"
																		className="text-subMain"
																		>
																		<span className="mr-2 inline-block">
																			<FileIcon extension={documento.documento_nombre.split(".").pop()} />
																		</span>
																		Descargar
																		</a>
																	</td>
																</tr>
															))
														}
														</tbody>
													</table>
													</div>
												):(
													recetasFromBack?.length === 0 && 
													(<div className="flex gap-3 flex-col w-full col-span-6">
														<p className="text-sm">Archivos Adjuntos</p>
														<div className="grid xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 w-full">
														{files.length > 0 &&
															files.map((file, i) => (
																<div className="relative w-full">
																	<img
																		src={file?.preview}
																		alt={file.name}
																		className="w-full  md:h-40 rounded-lg object-cover mt-5 p-1"
																		key={i}
																	/>
																	<button
																		onClick={() => deleteFile(i)}
																		className="bg-white rounded-full w-8 h-8 flex-colo absolute -top-1 -right-1"
																		>
																		<FaTimes className="text-red-500" />
																	</button>
																</div>
															))}
														</div>

														<MultipleUploader
															setFile={setFiles}
															file={files}
															title="Arrastre sus archivos aqui"
															placeholder="Solo se aceptan archivos tipo imagen y documento"
														/>
													</div>)
												)
											}
										</div>
									</div>
								</div>
							</div>
						</TabPanel>
					</>
				)}
				<TabPanel>
					{/* Analisis y laboratorios */}
					<div className=" grid grid-cols-12 gap-6 items-start">
						<div
							data-aos="fade-left"
							data-aos-duration="1000"
							data-aos-delay="100"
							data-aos-offset="200"
							className="col-span-12 lg:col-span-12 bg-white rounded-xl p-6"
						>
							<div>
								<div className="flex w-full flex-col gap-3">
									<div className="flex justify-between items-center">
										<p className="text-black text-lg">
											Análisis y Laboratorios
										</p>
										{/* <button className="flex justify-center items-center bg-subMain rounded-xl text-white text-center text-2xl p-2">
                    <FaFileDownload />
                  </button> */}
									</div>

									<div className="w-full overflow-x-scroll">
										<LaboratoryTable
											data={estudios}
											functions={{
												delete: (body) => {
													ConfirmationAlert({
														title: "Eliminar Orden",
														text: "Esta seguro de eliminar la orden?",
														onAccept: async () => {
															const res = await dispatch(deleteEstudio(body));
															if (res?.error) {
																toast.error(
																	"No se pudo eliminar la orden, " + res.error
																);
															} else {
																dispatch(getEstudiosByCitaId(cita?.id));
																toast.success("Orden eliminada correctamente!");
															}
														},
													});
												},
												printPdf: async (body) => {
													const blob = await pdf(
														<OrdenPdf
															data={{
																nombre: `${cita?.patient?.persona?.nombre} ${cita?.patient?.persona?.apellidos}`,
																edad: `${
																	cita?.patient?.persona?.fecha_nacimiento
																		? getAge(
																				new Date(
																					cita?.patient?.persona?.fecha_nacimiento
																				)
																		  ) + " años"
																		: ""
																}`,
																fecha: new Date().toLocaleDateString("en-GB"),
																lugarAtencion:
																	visita?.lugar_atencion ??
																	`${getValues("lugar_atencion")}`,
																seguro: `${cita?.patient?.empresa_seg ?? ""}`,
																orden: body,
															}}
														/>
													).toBlob();
													saveAs(
														blob,
														`Orden-${visita.id}-${new Date().toLocaleDateString(
															"en-GB"
														)}.pdf`
													);
												},
											}}
											button={true}
										/>
									</div>
									<button
										onClick={() => {
											setLabOpen(true);
										}}
										className=" text-subMain flex-rows gap-2 rounded-lg border border-subMain border-dashed py-4 w-full text-sm"
									>
										<BiPlus /> Agregar
									</button>
								</div>
							</div>
						</div>
					</div>
				</TabPanel>
			</Tabs>
		</>
	);
}

export default NewMedicalRecode;
