const FourColumnRow = ({children}) => {
    return (
      <div className="flex flex-col sm:flex-row w-full gap-3">
          <div className="sm:w-1/3">
            {children[0]}
          </div>
          <div className="sm:w-1/3">
            {children[1]}
          </div>
          <div className="sm:w-1/3">
            {children[2]}
          </div>
          <div className="sm:w-1/3">
            {children[3]}
          </div>
        </div>
    )
  }
  export default FourColumnRow;