import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getPayments = createAsyncThunk(
  "payments/getPayments",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/payments`);
    return data;
  }
);

export const getPaymentStatistics = createAsyncThunk(
  "payments/getPaymentStatistics",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/payments/statistics`);
    return data;
  }
);

export const getPaymentsById = createAsyncThunk(
  "payments/getPaymentsById",
  async (id) => {
    const { data } = await axios.get(`${apiUrl}/api/v1/payments/${id}`);
    return data;
  }
);

export const deletePayment = createAsyncThunk(
  "payments/deletePayment",
  async (id) => {
    const { data } = await axios.delete(`${apiUrl}/api/v1/payments/${id}`);
    return data;
  }
);

export const createPayment = createAsyncThunk(
  "payments/createPayment",
  async (body) => {
    const { data } = await axios.post(`${apiUrl}/api/v1/payments`, body);
    return data;
  }
);

const initialState = {
  status: "idle",
  paymentsStatus: "idle",
  payments: [],
  paymentsById: [],
  statistics: null,
  error: null,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(getPayments.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPayments.fulfilled, (state, action) => {
        state.status = "succeed";
        state.payments = action.payload;
      })
      .addCase(getPayments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getPaymentStatistics.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPaymentStatistics.fulfilled, (state, action) => {
        state.status = "succeed";
        state.statistics = action.payload;
      })
      .addCase(getPaymentStatistics.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getPaymentsById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getPaymentsById.fulfilled, (state, action) => {
        state.status = "succeed";
        state.paymentsById = action.payload;
      })
      .addCase(getPaymentsById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createPayment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.status = "succeed";
        state.payments = [...state.payments, action.payload];
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deletePayment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deletePayment.fulfilled, (state, action) => {
        state.status = "succeed";
      })
      .addCase(deletePayment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default paymentSlice.reducer;

export const getAllPayments = (state) => state.payment.payments;
export const getAllPaymentsById = (state) => state.payment.paymentsById;
export const getAllPaymentStatistics = (state) => state.payment.statistics;
export const getPaymentStatus = (state) => state.payment.status;
export const getPaymentError = (state) => state.payment.error;
