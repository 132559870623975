import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Aos from "aos";
import { Providers } from "./redux/providers";
import Dashboard from "./screens/Dashboard";
import Toast from "./components/Notifications/Toast";
import Payments from "./screens/Payments/Payments";
import Appointments from "./screens/Appointments";
import Patients from "./screens/Patients/Patients";
import Campaings from "./screens/Campaings";
import Services from "./screens/Services";
import Invoices from "./screens/Invoices/Invoices";
import Settings from "./screens/Settings";
import CreateInvoice from "./screens/Invoices/CreateInvoice";
import EditInvoice from "./screens/Invoices/EditInvoice";
import PreviewInvoice from "./screens/Invoices/PreviewInvoice";
import EditPayment from "./screens/Payments/EditPayment";
import PreviewPayment from "./screens/Payments/PreviewPayment";
import Medicine from "./screens/Medicine";
import PatientProfile from "./screens/Patients/PatientProfile";
import CreatePatient from "./screens/Patients/CreatePatient";
import Doctors from "./screens/Doctors/Doctors";
import DoctorProfile from "./screens/Doctors/DoctorProfile";
import Receptions from "./screens/Receptions";
import NewMedicalRecode from "./screens/Patients/NewMedicalRecode";
import NotFound from "./screens/NotFound";
import Login from "./screens/Login";
import Notifications from "./screens/Notifications";
import PatientsHistory from "./screens/Patients/PatientsHistory";
import PatientsAgenda from "./screens/Patients/PatientsAgenda";
import PatientsPayments from "./screens/Patients/PatientsPayments";
import PatientsInfo from "./screens/Patients/PatientsInfo";
import PatientsHealthInfo from "./screens/Patients/PatientsHealthInfo";
import Laboratories from "./screens/Patients/Laboratories.js";
import PatientLaboratories from "./screens/Patients/PatientLaboratories.js";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "./queryClient.js";
function App() {
  Aos.init();
  return (
    <Providers>
      <QueryClientProvider client={queryClient}>
        {/* Toaster */}
        <Toast />
        {/* <Toaster position="top-center" reverseOrder={false} /> */}
        {/* Routes */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            {/* invoce */}
            {/* <Route path="/invoices" element={<Invoices />} />
          <Route path="/invoices/create" element={<CreateInvoice />} />
          <Route path="/invoices/edit/:id" element={<EditInvoice />} />
          <Route path="/invoices/preview/:id" element={<PreviewInvoice />} /> */}
            {/* payments */}
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/payments" element={<Payments />} />
            <Route path="/payments/create" element={<EditPayment />} />

            <Route path="/laboratories" element={<Laboratories />} />
            <Route
              path="/patients/laboratories/:id"
              element={<PatientLaboratories />}
            />
            {/* <Route path="/payments/preview/:id" element={<PreviewPayment />} /> */}
            {/* patient */}
            <Route path="/patients" element={<Patients />} />
            <Route path="/patients/preview/:id" element={<PatientProfile />} />
            <Route path="/patients/history/:id" element={<PatientsHistory />} />
            <Route path="/patients/agenda/:id" element={<PatientsAgenda />} />
            <Route
              path="/patients/payments/:id"
              element={<PatientsPayments />}
            />
            <Route
              path="/patients/patient_info/:id"
              element={<PatientsInfo />}
            />
            <Route
              path="/patients/health_info/:id"
              element={<PatientsHealthInfo />}
            />
            <Route
              path="/patients/create"
              element={<CreatePatient operation="create" />}
            />
            <Route
              path="/patients/edit/:id"
              element={<CreatePatient operation="edit" />}
            />
            {/* <Route path="/patients/visiting/:id" element={<NewMedicalRecode />} /> */}
            {/* doctors */}
            {/* <Route path="/doctors" element={<Doctors />} />
          <Route path="/doctors/preview/:id" element={<DoctorProfile />} /> */}
            {/* reception */}
            {/* <Route path="/receptions" element={<Receptions />} /> */}
            {/* others */}
            {/* <Route path="/login" element={<Login />} /> */}
            <Route path="/appointments" element={<Appointments />} />
            {/* <Route path="/campaigns" element={<Campaings />} />
          <Route path="/medicine" element={<Medicine />} />
          <Route path="/services" element={<Services />} /> */}
            <Route path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Providers>
  );
}

export default App;
