import ThreeColumRow from "../../components/ThreeColumRow";

import { useParams } from "react-router-dom";
import Loading from "../Loading";
import { useAntecedent } from "../../customHooks/queries/useAntecedent";

function HealthInfomationView() {

  const { id } = useParams();
  
  const { data: antecedent, isLoading } = useAntecedent(id);

  return (
    <div className="flex flex-col justify-between gap-4">
      {isLoading ? (
        <Loading />
      ) : (
        <div className="w-full flex flex-col  gap-3">
          <ThreeColumRow>
            <p className=" text-xs font-light">
              <span className="text-xs block font-medium">Tipo Sangre:</span>{" "}
              {antecedent?.sangre}{" "}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs block font-medium">Altura:</span>{" "}
              {antecedent?.altura}
              {" m"}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs block font-medium">Peso:</span>{" "}
              {antecedent?.peso}
              {" kg"}
            </p>
          </ThreeColumRow>
          <ThreeColumRow>
            {/* email */}
            <p className="text-xs font-light">
              <span className="text-xs block font-medium">Fuma:</span>
              {antecedent?.fuma ? "SI" : "NO"}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs block font-medium">Vape:</span>
              {antecedent?.vape ? "SI" : "NO"}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs block font-medium">Alcohol:</span>
              {antecedent?.alcohol ? "SI" : "NO"}
            </p>
          </ThreeColumRow>
          <div>
            <p className="text-xs font-light">
              <span className="text-xs font-medium">Medicacion en uso:</span>{" "}
              {antecedent?.medicacion ? (antecedent?.medicacion_description?.replace(/\|/g, ", ") ?? "NO") : "NO"}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs font-medium">
                Historial familiar de enfermedades:
              </span>{" "}
              {antecedent?.enfermedades ? (antecedent?.enfermedades_description?.replace(/\|/g, ", ") ?? "NO") : "NO"}
            </p>
            <p className="text-xs font-light">
              <span className="text-xs font-medium">
                Datos Quirúrgicos:
              </span>{" "}
              {antecedent?.quirurgicos ? (antecedent?.quirurgicos_description?.replace(/\|/g, ", ") ?? "NO") : "NO"}
            </p>
          </div>
        </div>

      )}  
    </div>
  );
}

export default HealthInfomationView;
