import { confirmAlert } from "react-confirm-alert";
import { Button } from "./Form";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi";

const ConfirmationAlert = ({title, text, onAccept}) => {
  return (
  <>
  {
      confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui flex flex-col text-center gap-5 border border-gray-400 rounded-lg p-5 bg-white">
            <h1 className=" font-bold text-lg">
              {title}
            </h1>
            <p>
              {text}
            </p>
            <div className="flex flex-row gap-5 lg:gap-10">
              <Button label={"No"} Icon={HiOutlineXCircle} onClick={onClose} />
              <Button
                label={"Si"}
                Icon={HiOutlineCheckCircle}
                onClick={async () => {
                  onAccept();
                  onClose();
                }}
              />
            </div>
          </div>
        );
      },
    })
  }
  </>
  )};
 
export default ConfirmationAlert