import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { BiChevronLeft, BiChevronRight, BiPlus, BiTime } from "react-icons/bi";
import { HiOutlineViewGrid } from "react-icons/hi";
import { HiOutlineCalendarDays } from "react-icons/hi2";
import AddAppointmentModal from "../components/Modals/AddApointmentModal";
import {
  getAllCitas,
  getCitas,
  getCitasError,
  getCitasStatus,
} from "../redux/features/citasSlice";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients, getPatients } from "../redux/features/patientSlice";
import "moment/locale/es.js";

// custom toolbar
const CustomToolbar = (toolbar) => {
  // today button handler
  const goToBack = () => {
    if (toolbar.view === "month")
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    if (toolbar.view === "day")
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    if (toolbar.view === "week")
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    toolbar.onNavigate("prev");
  };

  // next button handler
  const goToNext = () => {
    if (toolbar.view === "month")
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    if (toolbar.view === "day")
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    if (toolbar.view === "week")
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    toolbar.onNavigate("next");
  };

  // today button handler
  const goToCurrent = () => {
    toolbar.onNavigate("TODAY");
  };

  // month button handler
  const goToMonth = () => {
    toolbar.onView("month");
  };

  // week button handler
  const goToWeek = () => {
    toolbar.onView("week");
  };

  // day button handler
  const goToDay = () => {
    toolbar.onView("day");
  };

  // view button group
  const viewNamesGroup = [
    { view: "month", label: "Month" },
    { view: "week", label: "Week" },
    { view: "day", label: "Day" },
  ];

  return (
    <div className="flex flex-col gap-8 mb-8">
      <h1 className="text-xl font-semibold">Agenda</h1>
      <div className="grid sm:grid-cols-2 md:grid-cols-12 gap-4">
        <div className="md:col-span-1 flex sm:justify-start justify-center items-center">
          <button
            onClick={goToCurrent}
            className="px-6 py-2 border border-subMain rounded-md text-subMain"
          >
            Hoy
          </button>
        </div>
        <div className="md:col-span-9 flex-rows gap-4">
          <button onClick={goToBack} className="text-2xl text-subMain">
            <BiChevronLeft />
          </button>
          <span className="text-xl font-semibold">
            {
              toolbar.view === "day" && moment(toolbar.date).format('D [de] MMMM [de] YYYY') ||
              moment(toolbar.date).format("MMMM YYYY").replace(/^([a-z])/, match => match.toUpperCase())
            }
          </span>
          <button onClick={goToNext} className="text-2xl text-subMain">
            <BiChevronRight />
          </button>
        </div>

        <div className="md:col-span-2 grid grid-cols-3 rounded-md  border border-subMain">
          {viewNamesGroup.map((item, index) => (
            <button
              key={index}
              onClick={
                item.view === "month"
                  ? goToMonth
                  : item.view === "week"
                  ? goToWeek
                  : goToDay
              }
              className={`border-l text-xl py-2 flex-colo border-subMain ${
                toolbar.view === item.view
                  ? "bg-subMain text-white"
                  : "text-subMain"
              }`}
            >
              {item.view === "month" ? (
                <HiOutlineViewGrid />
              ) : item.view === "week" ? (
                <HiOutlineCalendarDays />
              ) : (
                <BiTime />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

function Appointments() {
  const localizer = momentLocalizer(moment);
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState({});
  const [events, setEvents] = useState([]);

  const dispatch = useDispatch();
  const citas = useSelector(getAllCitas);
  const patients = useSelector(getAllPatients);
  const citasStatus = useSelector(getCitasStatus);
  const citasError = useSelector(getCitasError);

  useEffect(() => {
    dispatch(getCitas());
    dispatch(getPatients());
    setEvents(
      citas?.map((cita) => {
        const start = new Date(cita.hora_inicio);
        const end = new Date(cita.hora_fin);
        return {
          ...cita,
          start,
          end,
          title:
            cita?.patient?.persona?.nombre +
            " " +
            cita?.patient?.persona?.apellidos,
        };
      })
    );
  }, [dispatch, citas?.length]);

  // handle modal close
  const handleClose = () => {
    setOpen(!open);
    setData({});
  };

  // onClick event handler
  const handleEventClick = (event) => {
    setData(event);
    setOpen(!open);
  };

  return (
    <Layout>
      {open && (
        <AddAppointmentModal
          datas={data}
          patients={patients?.data}
          isOpen={open}
          closeModal={() => {
            handleClose();
          }}
        />
      )}
      {/* calender */}
      <button
        onClick={handleClose}
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </button>

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        min={new Date(0, 0, 0, 7, 0, 0)}
        max={new Date(0, 0, 0, 21, 0, 0)}
        style={{
          // height fix screen
          height: '82vh',
          marginBottom: 50,
          minHeight: '650px'
        }}
        onSelectEvent={(event) => handleEventClick(event)}
        defaultDate={new Date()}
        timeslots={3}
        step={5}
        resizeable
        selectable={true}
        messages={{
          month: "Mes",
          week: "Semana",
          day: "Dia",
          showMore: (number) => `+${number} mas . . .`
        }}
        // custom event style
        eventPropGetter={(event) => {
          const style = {
            backgroundColor:
              event.estado === "en curso"
                ? "#66B5A3"
                : event.estado === "cerrada"
                ? "#05B10C"
                : "#E23F33",
            borderRadius: "10px",
            color: "white",
            border: "1px solid #fff",
            borderColor: "#fff",
            fontSize: "10px",
            padding: "5px 5px",
          };
          return {
            style,
          };
        }}
        // custom date style
        dayPropGetter={(date) => {
          const style = {
            backgroundColor: 'white',
          };
          return {
            style,
          };
        }}
        // remove agenda view
        //views={["month", "day", "week"]}
        //toolbar={false}
        components={{ toolbar: CustomToolbar }}
      />
    </Layout>
  );
}

export default Appointments;
