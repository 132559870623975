import LayoutPatient from "../../LayoutPatient";
import PatientView360 from "./PatientView360";

function PatientProfile() {
  return (
    <LayoutPatient title="">
      <div className="grid grid-cols-12 gap-6  items-start">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="col-span-12 bg-text rounded-xl border-[1px] border-border p-5"
        >
          <PatientView360 />
        </div>
      </div>
    </LayoutPatient>
  );
}

export default PatientProfile;
