import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";
import { showCustomToast } from "../../utils/NotificationToast";

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/notificaciones`);
    return data;
  }
);

export const getNewNotifications = createAsyncThunk(
  "notifications/getNewNotifications",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/lastnotificaciones`);
    return data;
  }
);

export const deleteNotification = createAsyncThunk(
  "notifications/bajaNotification",
  async (id) => {
    const { data } = await axios.delete(
      `${apiUrl}/api/v1/notificaciones/${id}`
    );
    return data;
  }
);

export const createNotification = createAsyncThunk(
  "notifications/createNotification",
  async (body) => {
    if (body?.id) {
      const { data } = await axios.post(
        `${apiUrl}/api/v1/notificaciones/${body.id}`,
        body
      );
      return data;
    } else {
      const { data } = await axios.post(
        `${apiUrl}/api/v1/notificaciones`,
        body
      );
      return data;
    }
  }
);

export const deleteNewNotification = createAction(
  "notifications/deleteNewNotification"
);

const initialState = {
  status: "idle",
  notifications: [],
  newNotifications: [],
  error: null,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getNotifications.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.status = "succeed";
        state.notifications = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getNewNotifications.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getNewNotifications.fulfilled, (state, action) => {
        state.status = "succeed";

        if (Array.isArray(action.payload)) {
          while (state?.newNotifications.length > 10) {
            // Filtra los elementos leídos
            const readItems = state?.newNotifications?.filter(
              (item) => item.leido
            );

            // Si no hay elementos leídos, no elimines nada
            if (readItems.length === 0) break;

            // Encuentra el elemento leído más antiguo
            const oldestReadItem = readItems.reduce((oldest, item) => {
              return item.fecha < oldest.fecha ? item : oldest;
            }, readItems[0]);

            // Elimina el elemento leído más antiguo del array original
            const index = state?.newNotifications?.indexOf(oldestReadItem);
            if (index > -1) {
              state?.newNotifications?.splice(index, 1);
            }
          }

          action.payload.forEach((newNotification) => {
            const existingIndex = state.newNotifications.findIndex(
              (notification) => notification.id === newNotification.id
            );
            if (existingIndex === -1) {
              // Uso de la función para mostrar el toast
              showCustomToast({
                patient: {
                  persona: {
                    nombre: newNotification.patient.persona.nombre,
                    apellidos: newNotification.patient.persona.apellidos,
                  },
                },
                motivo: newNotification.motivo,
              });
              state.newNotifications.push({ ...newNotification, leido: false });
            }
          });

          // Ordenar las notificaciones por fecha (la fecha menor primero)
          state.newNotifications.sort(
            (a, b) => new Date(a.fecha) - new Date(b.fecha)
          );
        }
      })
      .addCase(getNewNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createNotification.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.status = "succeed";
        state.payments = [...state.notifications, action.payload];
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteNotification.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteNotification.fulfilled, (state, action) => {
        state.status = "succeed";
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteNewNotification, (state, action) => {
        state.newNotifications = state.newNotifications.map((notification) => ({
          ...notification,
          leido: true,
        }));
      });
  },
});

export default notificationSlice.reducer;

export const getAllNotifications = (state) => state.notification.notifications;
export const getAllNewNotifications = (state) =>
  state.notification.newNotifications;
export const getNotificationStatus = (state) => state.notification.status;
export const getNotificationError = (state) => state.notification.error;
