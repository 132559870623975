import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { getAge } from "../utils/age";

Font.register({
  family: "Roboto",
  fonts: [
    { src: "http://fonts.gstatic.com/s/roboto/v15/W5F8_SL0XFawnjxHGsZjJA.ttf" },
    {
      src: "http://fonts.gstatic.com/s/roboto/v15/bdHGHleUa-ndQCOrdpfxfw.ttf",
      fontStyle: "normal",
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    color: "#fff",
    height: "100%",
    paddingBottom: 70,
  },
  header: {
    paddingHorizontal: 40,
    paddingTop: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  logo: {
    width: "auto",
    height: 75,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontFamily: "Roboto",
    fontWeight: 700,
    color: "#000",
    marginBottom: 20,
  },
  subtitle: {
    textAlign: "left",
    fontSize: 14,
    color: "#000",
    fontFamily: "Roboto",
    fontWeight: 700,
    marginBottom: 20,
  },
  section: {
    paddingHorizontal: 40,
    marginBottom: 10,
  },
  bold: {
    color: "#000",
    fontFamily: "Roboto",
  },
  text: {
    marginBottom: 5,
    fontSize: 10,
    fontFamily: "Roboto",
    fontWeight: 400,
    color: "#000",
  },
  footerImage: {
    width: "100%",
    height: "8%",
    position: "absolute",
    bottom: 0,
  },
  watermark: {
    position: "absolute",
    top: "35%",
    left: "15%",
    width: "70%",
    opacity: 0.2,
    zIndex: "-10",
  },
  gridContainer: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 50,
    marginBottom: 20,
  },
  gridItem: {
    width: "100%", // two items per row, with some space between
    marginBottom: 5,
  },
  singleItem: {
    width: "100%",
  },
  information: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 40,
    marginBottom: 10,
    fontFamily: "Roboto",
  },
});

const HistoriaPdf = ({ data }) => {
  return (
    <Document>
      <Page style={styles.page}>
        <Image fixed style={styles.watermark} src="/images/longLogo.png" />
        {/* Header */}
        <View fixed style={styles.header}>
          <Image style={styles.logo} src="/images/smallLogo.png" />
        </View>

        {/* Title */}
        <Text style={styles.title}>Historia Clínica</Text>

        {/* Patient Information */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>1. Filiación</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Apellido y Nombre:</Text>{" "}
              {data?.patient?.persona?.apellidos +
                " " +
                data?.patient?.persona?.nombre}
              
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Número de HC:</Text>{" "}
              {data?.visita?.id}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Fecha Nacimiento:</Text>{" "}
              {new Date(
                data?.patient?.persona.fecha_nacimiento
              ).toLocaleDateString("en-gb") ?? ""}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Edad:</Text>{" "}
              {getAge(new Date(data?.patient?.persona?.fecha_nacimiento)) ?? ""}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Sexo:</Text>{" "}
              {data?.patient?.persona?.sexo}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Procedencia:</Text>
              {data?.patient?.persona?.nacimiento ?? ""}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Residencia:</Text>
              {data?.patient?.persona?.residencia ?? ""}
            </Text>
          </View>
        </View>

        {/* Motivo de Consulta */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>2. Motivo de Consulta</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {data?.visita?.motivo?.replaceAll(",", ", ")}
            </Text>
          </View>
        </View>

        {/* Historia Actual */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>3. Historia Actual</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {data?.visita?.desglose_motivo}
            </Text>
          </View>
        </View>

        {/* Antecedentes */}
        {/* <View style={styles.information}>
          <View style={styles.gridItem}>
          <Text style={styles.subtitle}>4. Antecedentes</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {"Sera definido..."}
            </Text>
          </View>
        </View> */}

        {/* signos vitales */}
        {/* <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>4. Examen Fisico General</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Frecuencia Cardiaca:</Text>
              {data?.visita?.f_cardiaca}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Frecuencia Respiratoria:</Text>
              {data?.visita?.f_respiratoria}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Presión Arterial 1':</Text>
              {data?.visita?.p_arterial_1}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Presión Arterial 5:</Text>
              {data?.visita?.p_arterial_5}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Temperatura:</Text>
              {data?.visita?.temperatura}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Peso:</Text>
              {data?.visita?.peso}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Altura:</Text>
              {data?.visita?.altura}
            </Text>
            <Text style={styles.text}>
              <Text style={styles.bold}>Examen Físico General:</Text>
              {data?.visita?.examen_fisico}
            </Text>
          </View>
        </View> */}

        {/* Diagnostico */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>4. Diagnóstico</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {data?.visita?.diagnostico?.replaceAll(",", ", ").slice(2, -1) ??
                ""}
            </Text>
          </View>
        </View>

        {/* Conducta */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>5. Conducta</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {data?.visita?.conducta ?? ""}
            </Text>
          </View>
        </View>

        {/* Comentarios */}
        <View style={styles.information}>
          <View style={styles.gridItem}>
            <Text style={styles.subtitle}>6. Comentarios</Text>
            <Text style={styles.text}>
              <Text style={styles.bold}></Text>
              {data?.visita?.comentarios ?? ""}
            </Text>
          </View>
        </View>

        {/* Footer */}
        <Image fixed style={styles.footerImage} src="/images/pdfFooter.png" />
      </Page>
    </Document>
  );
};

export default HistoriaPdf;
