import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-hot-toast";
import { BiLoaderCircle } from "react-icons/bi";
import { FiUploadCloud } from "react-icons/fi";

const MultipleUploader = ({
  setFile,
  file,
  register,
  srcs,
  title = "Arrastre su imagen aqui",
  placeholder = "(Solo se aceptan imagenes)",
}) => {
  const [loading, setLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    setLoading(true);
    setFile(
      acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      })
    );
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: true,
    onDrop,
    accept: {
      "image/png": [],
      "image/jpeg": [],
      "image/jpg": [],
      "application/pdf": [],
      "application/msword": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
      "text/plain": [],
      "video/mp4": [],
      "video/mpeg": [],
      "video/quicktime": [],
      "audio/mpeg": [],
      "audio/wav": [],
      "audio/mp3": [],
      "audio/mp4": [],
      "audio/ogg": [],
      "audio/webm": [],
    },
  });

  return (
    <div className="w-full text-center grid grid-cols-12 gap-4">
      <div
        className="px-6 lg:col-span-10 sm:col-span-8 col-span-12 pt-5 pb-6 border-2 border-dashed rounded-md cursor-pointer"
        {...getRootProps()}
      >
        <input {...getInputProps()} {...register} type="file" />
        <span className="mx-auto flex justify-center">
          <FiUploadCloud className="text-3xl text-subMain" />
        </span>
        <p className="text-sm mt-2">{title}</p>
        <em className="text-xs text-gray-400">{placeholder}</em>
      </div>
      {/* image preview */}
      <div className="lg:col-span-2 sm:col-span-4 col-span-12">
        {loading && (
          <div className="px-6 w-full bg-dry flex-colo h-32 border-2 border-border border-dashed rounded-md">
            <BiLoaderCircle className="mx-auto text-main text-3xl animate-spin" />
            <span className="text-sm mt-2 text-text">Uploading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default MultipleUploader;
