import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { toast } from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import {
  Button,
  Input,
  Select,
  Textarea,
} from "../../components/Form";
import { BiChevronDown } from "react-icons/bi";
import { RiSave3Fill } from "react-icons/ri";
import SearchBar from "../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getAllPatients, getPatients } from "../../redux/features/patientSlice";
import { useForm } from "react-hook-form";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import {
  createPayment,
  getPaymentStatus,
  getPayments,
} from "../../redux/features/paymentSlice";
import Loading from "../Loading";

const metodosPago = [
  {
    id: 1,
    name: "Efectivo",
  },
  {
    id: 2,
    name: "Tarjeta",
  },
  {
    id: 3,
    name: "Transferencia",
  },
  {
    id: 4,
    name: "QR",
  },
  {
    id: 5,
    name: "Otro",
  },
];

function EditPayment({ operation = "create" }) {
  const [selected, setSelected] = useState(null);
  const [metodoPago, setMetodoPago] = useState(metodosPago[0]);

  const { id } = useParams();

  const patients = useSelector(getAllPatients);
  const paymentStatus = useSelector(getPaymentStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getPatients());
  }, [dispatch]);
  const {
    register,
    setValue: setFormValue,
    getValues: getFormValue,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const sendPayment = async (data) => {
    const res = await dispatch(createPayment(data));
    if (res?.error) {
      toast.error(
        "No se pudo realizar el registro de pago: " + res?.error?.message,
        { duration: 5000 }
      );
    } else {
      toast.success("Pago realizado con exito!", { duration: 5000 });
      dispatch(getPayments());
      if(id) navigate(0);
      else {
        navigate(-1);
      }
    }
  };
  const onSubmit = async (data) => {
    if (!selected?.id) {
      toast.error("Seleccione paciente primero");
      return;
    }
    data.patient_id = selected.id;
    data.metodo = metodoPago.name;
    ConfirmationAlert({
      title: "Registro de Pago",
      text: "Esta seguro de registrar el pago?",
      onAccept: () => sendPayment(data),
    });
  };
  return (
    <Layout>
      {paymentStatus === "loading" ? (
        <Loading />
      ) : (
        <>
          <div className="flex items-center gap-4">
            <Link
              onClick={() => navigate(-1)}
              className="bg-white border border-subMain border-dashed rounded-lg py-3 px-4 text-md"
            >
              <IoArrowBackOutline />
            </Link>
            <h1 className="text-xl font-semibold">{`${
              operation !== "create" ? "Editar " : "Nuevo "
            }Pago`}</h1>
          </div>
          <div
            // data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="100"
            data-aos-offset="200"
            className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
          >
            {/* header */}
            <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-2 items-center">
              <div className="lg:col-span-3 flex items-center gap-2">
                <img
                  src="/images/logo.png"
                  alt="logo"
                  className=" w-32 object-contain"
                />
              </div>

              <div className="flex flex-col gap-4 sm:items-end">
                {/* <h6 className="text-xs font-medium">#324234</h6> */}
                <div className="flex gap-4">
                  <p className="text-sm font-extralight">Fecha:</p>
                  <h6 className="text-xs font-medium">{ `${new Date().toLocaleDateString("es-BO")}` }</h6>
                </div>
                <div className="flex gap-4">
                  <p className="text-sm font-extralight">Id Paciente:</p>
                  <h6 className="text-xs font-medium">{selected?.id ?? ""}</h6>
                </div>
              </div>
            </div>
            {/* sender and recever */}
            {/* <SenderReceverComp item={{title: 'Piter Cardenas Vargas', email: 'piter@gmail.com', phone: '76166666'}} functions={{}} button={false} /> */}
            {/* products */}
            <div className="grid grid-cols-6 gap-6 mt-8 items-start">
              <div className="lg:col-span-6 col-span-6">
                {!id && (
                  <SearchBar
                    placeholder="Escriba nombre o CI para buscar al paciente"
                    data={patients.data}
                    setSelected={setSelected}
                    value={
                      selected
                        ? `${selected.persona.ci} - ${selected.persona.nombre} ${selected.persona.apellidos}`
                        : null
                    }
                  />
                )}
                {/* <SearchInput
              label="Paciente"
              name="patient"
              type="text"
              color={true}
              placeholder="Nombre o CI"
            /> */}
                <div className="p-6 border grid grid-cols-1 sm:grid-cols-3 gap-3 border-border rounded-xl w-full my-5">
                  <Input
                    label="Monto"
                    type="number"
                    color={true}
                    placeholder="0.0s"
                    register={register("monto", {
                      required: "Este campo es requerido",
                      min: {
                        value: 0.01,
                        message: "El monto debe ser mayor a 0 Bs.",
                      },
                    })}
                    error={errors.monto}
                  />

                  <div className="flex w-full flex-col gap-3">
                    <p className="text-black text-sm">Método de Pago</p>
                    <Select
                      selectedPerson={metodoPago}
                      setSelectedPerson={setMetodoPago}
                      datas={metodosPago}
                    >
                      <div className="w-full flex-btn text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                        {metodoPago?.name} <BiChevronDown className="text-xl" />
                      </div>
                    </Select>
                  </div>

                  {/* <Input
                    label="Metodo de pago"
                    type="text"
                    color={true}
                    placeholder="Efectivo | QR | Tarjeta"
                    register={register("metodo", {
                      required: "Este campo es requerido",
                    })}
                    error={errors.metodo}
                  /> */}
                  <Input
                    label="Motivo"
                    type="text"
                    color={true}
                    placeholder="Consulta | Laboratorio"
                    register={register("motivo", {
                      required: "Este campo es requerido",
                    })}
                    error={errors.motivo}
                  />
                </div>
                <Textarea
                  label="Observaciones"
                  placeholder="Detalles u observaciones del Pago"
                  color={true}
                  rows={3}
                  className="col-span-3"
                  register={register("observaciones")}
                  name="observaciones"
                  setValue={setFormValue}
                  getValue={getFormValue}
                />
                {/* notes */}
                {/* <div className="w-full my-8">
              <p className="text-sm mb-3">Change Status</p>
              <Select
                selectedPerson={selected}
                setSelectedPerson={setSelected}
                datas={sortsDatas?.status}
              >
                <div className="h-14 w-full text-xs text-main rounded-md border border-border px-4 flex items-center justify-between">
                  <p>{selected?.name}</p>
                  <BiChevronDown className="text-xl" />
                </div>
              </Select>
            </div> */}
              </div>
            </div>
            <Button
              label="Registrar Pago"
              onClick={handleSubmit(onSubmit)}
              Icon={RiSave3Fill}
            />
          </div>
        </>
      )}
    </Layout>
  );
}

export default EditPayment;
