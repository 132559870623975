import React, { useEffect, useState } from "react";
import Layout from "../../Layout";
import { memberData, sortsDatas } from "../../components/Datas";
import { Link, useNavigate } from "react-router-dom";
import { BiChevronDown, BiPlus, BiTime } from "react-icons/bi";
import { BsCalendarMonth } from "react-icons/bs";
import ReactLoading from "react-loading";
import { MdFilterList, MdOutlineCalendarMonth } from "react-icons/md";
import { toast } from "react-hot-toast";
import { Button, FromToDate, Select } from "../../components/Form";
import { PatientTable } from "../../components/Tables";
//import { getPatients } from '../../redux/services/apiCalls';
import NotFound from "../NotFound";
import WithoutData from "../WithoutData";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPatients,
  getPatientError,
  getPatientStatus,
  getPatients,
  setPatient,
  updatePatient,
} from "../../redux/features/patientSlice";
import Loading from "../Loading";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi";
import { confirmAlert } from "react-confirm-alert";

function Patients() {
  const [filtro, setFiltro] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const patients = useSelector(getAllPatients);
  const patientStatus = useSelector(getPatientStatus);
  const patientError = useSelector(getPatientError);
  useEffect(() => {
    dispatch(getPatients());
    setfilteredData(patients?.data ?? []);
  }, [patients?.length]);

  if (patientStatus === "loading") return <Loading />;
  if (patientStatus === "failed") {
    toast.error(`${patientError}`);
  }
  


  // preview
  const previewPayment = (id) => {
    navigate(`/patients/preview/${id}`);
  };

  const editPatient = (id) => {
    navigate(`/patients/edit/${id}`);
  };

  const bajaPatient = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui flex flex-col text-center gap-5 border border-red-500 rounded-lg p-5 bg-white">
            <h1 className=" font-bold text-lg">Esta seguro de dar baja al paciente?</h1>
            <p className="font-light">Se perderan todos los datos relacionados al paciente!.</p>
            <div className="flex flex-row gap-5 lg:gap-10">
              <Button label={"No"} Icon={HiOutlineXCircle} onClick={onClose} />
              <Button
                label={"Si"}
                Icon={HiOutlineCheckCircle}
                onClick={async () => {
                  const newData = await dispatch(updatePatient({ id, estado: false }));

                  if (newData.error) {
                    toast.error(
                      `Ocurrio un error al dar de Baja al Paciente: ${newData.error}`,
                      {
                        duration: 5000,
                      }
                    );
                  } else {
                    toast.success("Paciente dado de Baja", {
                      duration: 5000,
                    });
                    navigate(0);
                  }
                  onClose();
                }}
              />
            </div>
          </div>
        );
      },
    });
  };

  const hanndleFilter = (e) => {
    setFiltro(e.target.value.toLowerCase());
    if(filtro === '' || e.target.value === '') {
      setfilteredData(patients?.data);
    } else {
      setfilteredData(patients?.data.filter(item => item.persona.nombre.toLowerCase().includes(filtro) || item.persona.apellidos.toLowerCase().includes(filtro) || item.persona.ci.toLowerCase().includes(filtro)));
    }
  }

  return (
    <Layout>
      {/* add button */}
      <Link
        to="/patients/create"
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </Link>
      <h1 className="text-xl font-semibold">Pacientes</h1>
      {/* boxes */}
      
      {/* datas */}
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="10"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="grid lg:grid-cols-1 grid-cols-1 xs:grid-cols-1 md:grid-cols-1 gap-2">
          <input
            type="text"
            placeholder="Filtrar por Nombre o CI"
            value={filtro}
            onChange={(e) => hanndleFilter(e)}
            className="h-14 text-sm text-main rounded-md bg-dry border border-border px-4"
          />
          {/* sort 
          {sorts.map((item) => (
            <Select
              key={item.id}
              selectedPerson={item.selected}
              setSelectedPerson={item.setSelected}
              datas={item.datas}
            >
              <div className="h-14 w-full text-xs text-main rounded-md bg-dry border border-border px-4 flex items-center justify-between">
                <p>{item.selected.name}</p>
                <BiChevronDown className="text-xl" />
              </div>
            </Select>
          ))} */}
          {/* date
          <FromToDate
            startDate={startDate}
            endDate={endDate}
            bg="bg-dry"
            onChange={(update) => setDateRange(update)}
          /> */}
          {/* export */}
          {/* <Button
            label="Filtrar"
            Icon={MdFilterList}
            onClick={() => {
              toast.error("No disponible aun");
            }}
          /> */}
        </div>
        <div className="mt-8 w-full overflow-auto">
          {
            patients?.data?.length > 0 && (
              <PatientTable
                data={filteredData}
                functions={{
                  preview: previewPayment,
                  edit: editPatient,
                  delete: bajaPatient,
                }}
                used={false}
              />
            )
            // : <WithoutData text="Crear Paciente" url="/patients/create"/>
          }
        </div>
      </div>
    </Layout>
  );
}

export default Patients;
