import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../apiUrl";

export const getRecetasById = createAsyncThunk(
  "recetas/getRecetasById",
  async (id) => {
    const { data } = await axios.get(`${apiUrl}/api/v1/recetas/${id}`);
    return data;
  }
);

export const getMedicamentos = createAsyncThunk(
  "recetas/getMedicamentos",
  async () => {
    const { data } = await axios.get(`${apiUrl}/api/v1/recetasMedicamentos`);
    return data;
  }
);

export const deleteRecetaByVisitaId = createAsyncThunk(
  "recetas/deleteReceta",
  async (visitaId) => {
    const { data } = await axios.delete(`${apiUrl}/api/v1/recetas/${visitaId}`);
    return data;
  }
);

export const createReceta = createAsyncThunk(
  "recetas/createreceta",
  async (body) => {
    const fd = new FormData();
    if (body.recetas_image) {
      for (let i in body.recetas_image) {
        console.log(`file_${i}`, body.recetas_image[i]);
        fd.append(`file_${i}`, body.recetas_image[i], body.recetas_image[i].name);
      }
    }
    for (const key in body) {
      const value = body[key];
      if (value !== undefined && value !== null) {
        fd.append(key, value);
      }
    }
    console.log("Data files: ", fd);

    for (let pair of fd.entries()) {
    	console.log(pair[0], pair[1]);
    }

    const { data } = await axios.post(`${apiUrl}/api/v1/recetas`, fd);
    return data;
  }
);

const initialState = {
  status: "idle",
  recetas: [],
  medicamentos: [],
  documentos: [],
  error: null,
};

export const recetaSlice = createSlice({
  name: "receta",
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(getRecetasById.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getRecetasById.fulfilled, (state, action) => {
        state.status = "succeed";
        state.recetas = action.payload.recetas;
        state.documentos = action.payload.documento;
      })
      .addCase(getRecetasById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createReceta.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createReceta.fulfilled, (state, action) => {
        state.status = "succeed";
      })
      .addCase(createReceta.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getMedicamentos.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getMedicamentos.fulfilled, (state, action) => {
        state.status = "succeed";
        state.medicamentos = action.payload;
      })
      .addCase(getMedicamentos.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(deleteRecetaByVisitaId.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteRecetaByVisitaId.fulfilled, (state, action) => {
        state.status = "succeed";
        state.recetas = [];
      })
      .addCase(deleteRecetaByVisitaId.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default recetaSlice.reducer;

export const getAllDocumentos = (state) => state.receta.documentos;
export const getAllRecetas = (state) => state.receta.recetas;
export const getAllMedicamentos = (state) => state.receta.medicamentos;
export const getRecetasStatus = (state) => state.receta.status;
export const getRecetasError = (state) => state.receta.error;
