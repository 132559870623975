import { useState } from "react";
import { Button, Input } from "../Form";
import Modal from "./Modal";
import TwoColumRow from "../TwoColumRow";
import { useForm } from "react-hook-form";
import { HiOutlineCheckCircle } from "react-icons/hi";
import ConfirmationAlert from "../ConfirmationAlert";
import { useDispatch } from "react-redux";
import { createPatient, getPatients } from "../../redux/features/patientSlice";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

function NewPatientFast({ open, setOpen, setPatient }) {
  const dispatch = useDispatch();
  const fakeCi = uuidv4();

  const onSubmit = (data) => {
    data.ci = fakeCi;
    data.sexo = "Otro";
    ConfirmationAlert({
      title: "Registro Rapido de Paciente",
      text: `Estas seguro de registrar al Paciente`,
      onAccept: async () => {
        const newData = await dispatch(createPatient(data));
        if (newData?.error) {
          toast.error(`No se pudo registrar al paciente: ${newData.error}`, {
            duration: 5000,
          });
        } else {
          toast.success("Paciente registrado con exito!", {
            duration: 5000,
          });
          let newPatient = {};
          dispatch(getPatients());
          newPatient.id = newData?.payload?.patient?.id;
          newPatient.persona = newData?.payload?.person;
          setPatient(newPatient);
          setOpen(false);
        }
      },
    });
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  return (
    <Modal
      title="Crear Paciente"
      isOpen={open}
      closeModal={() => {
        setOpen(false);
      }}
      width={"max-w-3xl"}
    >
      <div className="flex flex-colo gap-5">
        <TwoColumRow>
          <Input
            label="Nombres"
            color={true}
            type="text"
            register={register("nombre", {
              required: "Este campo es requerido",
              maxLength: 30,
            })}
            error={errors.nombre}
          />
          <Input
            label="Apellidos"
            color={true}
            type="text"
            register={register("apellidos", {
              required: "Este campo es requerido",
              maxLength: 30,
            })}
            error={errors.apellidos}
          />
        </TwoColumRow>
        <Button
          label={"Registrar"}
          Icon={HiOutlineCheckCircle}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </Modal>
  );
}
export default NewPatientFast;
