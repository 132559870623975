
const TwoColumRow = ({children}) => {
  return (
    <div className="flex flex-col sm:flex-row w-full gap-3">
        <div className="w-full sm:w-1/2">
          {children[0]}
        </div>
        <div className="w-full sm:w-1/2">
          {children[1]}
        </div>
      </div>
  )
}
export default TwoColumRow;