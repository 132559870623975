import { useEffect, useState } from "react";
import LayoutPatient from "../../LayoutPatient";
import { useNavigate, useParams } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { getAge } from "../../utils/age";
import Loading from "../Loading";
import {
  deletePayment,
  getAllPaymentsById,
  getPaymentStatus,
  getPaymentsById,
} from "../../redux/features/paymentSlice";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import toast from "react-hot-toast";
import { Transactiontable } from "../../components/Tables";
import Modal from "../../components/Modals/Modal";
import EditPaymentForm from "../Payments/EditPaymentForm";
import { usePatient } from "../../customHooks/queries/usePatient";

function PatientPayments() {
  const navigate = useNavigate();
  const [close, setClose] = useState(false);

  const editPayment = (id) => {
    navigate(`/payments/edit/${id}`);
  };
  // preview
  const previewPayment = (id) => {
    navigate(`/payments/preview/${id}`);
  };

  const bajaPayment = async (id) => {
    ConfirmationAlert({
      title: "Dar baja pago",
      text: "Esta seguro de dar de baja el registro de pago?",
      onAccept: async () => {
        const res = await dispatch(deletePayment(id));
        if (res.error) {
          toast.error(res.error.message, { duration: 5000 });
        } else {
          toast.success("Pago dado de baja con exito!", { duration: 5000 });
          navigate(0);
        }
      },
    });
  };

  const { id } = useParams();
  const { data: patient, isLoading } = usePatient(id);

  const dispatch = useDispatch();

  const paymentsStatus = useSelector(getPaymentStatus);
  const payments = useSelector(getAllPaymentsById);
  useEffect(() => {
    dispatch(getPaymentsById(id));
  }, [patient?.data, dispatch, id]);

  if (isLoading) return <Loading />;

  return (
    <LayoutPatient title="">
      <Modal
        closeModal={() => {
          setClose(!close);
        }}
        isOpen={close}
      >
        <EditPaymentForm />
      </Modal>
      <div className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb">
        <BiPlus onClick={() => setClose(!close)} className="text-2xl" />
      </div>

      <div className=" grid grid-cols-12 gap-2 items-start">
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="hidden md:block col-span-3 bg-white rounded-xl border-[1px] border-border p-6"
        >
          {!patient.data ? (
            <Loading />
          ) : (
            <div
              data-aos="fade-right"
              data-aos-duration="1000"
              data-aos-delay="100"
              data-aos-offset="200"
              className="col-span-12 flex-colo gap-6 lg:col-span-4 bg-white p-6 lg:sticky top-28"
            >
              <img
                src={
                  patient
                    ? patient.data.persona?.perfil
                    : "https://png.pngtree.com/png-vector/20230131/ourmid/pngtree-flat-user-profile-icon-on-isolated-background-with-permission-sign-vector-png-image_49602760.jpg"
                }
                alt="setting"
                className="w-20 h-20 rounded-full object-cover border border-dashed border-subMain"
              />
              <div className="gap-2 flex-colo">
                <h2 className="text-sm font-semibold">{`${patient.data?.persona?.nombre} ${patient.data?.persona?.apellidos}`}</h2>
                <a
                  href={`mailto:${patient.data?.persona?.email}`}
                  className="text-xs text-textGray"
                >
                  {patient.data?.persona?.email}
                </a>
                <a
                  href={`https://wa.me/+571${patient.data?.persona?.telf1}/`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-xs"
                >
                  {patient.data?.persona?.telf1}
                </a>
                <p className="text-xs text-subMain bg-text font-medium py-1 px-4 rounded-full border-[0.5px] border-subMain">
                  {getAge(new Date(patient.data.persona?.fecha_nacimiento))}{" "}
                  años{" "}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1000"
          data-aos-delay="100"
          data-aos-offset="200"
          className="col-span-12 md:col-span-9 bg-white rounded-xl border-[1px] border-border p-6 overflow-auto"
        >
          <h2 className="font-semibold p-2 mb-2">Pagos de Paciente</h2>
          <Transactiontable
            status={paymentsStatus}
            data={payments}
            action={true}
            uniquePatient={true}
            functions={{
              edit: editPayment,
              preview: previewPayment,
              delete: bajaPayment,
            }}
          />
        </div>
      </div>
    </LayoutPatient>
  );
}

export default PatientPayments;
