import { useRef, useState } from "react";
import useOnClickOutside from "../customHooks/useOnClickOutside";


const MultiSelect = ({ options, value, setSelected, placeholder }) => {
  const [focus, setFocus] = useState(false);
  
  const myRefElement1 = useRef(null);
  useOnClickOutside(myRefElement1, () => setFocus(false));

  const handleSelect = (item) => {
    if(!value.includes(item)) {
      setSelected(prev => [...prev, item]);
    } else {
      handleDelete(item);
    }
  };
  const handleDelete = (item) => {
    setSelected(prev => prev.filter(el => el.id !== item.id));

  }

  return (
    <div className="relative" ref={myRefElement1}>
      <div className="flex items-center w-full bg-transparent text-sm my-1 p-4 border font-lig rounded-lg border-border overflow-auto" onClick={() => setFocus(true)}>
        {value?.length === 0 ? (
          <p className=" text-slate-500 font-light font">{placeholder}</p>
        ) : (
          value.map((item, index) => <span key={index} className=" flex-shrink-0 text-xs text-white bg-subMain rounded-lg p-1 mr-1">{item.name}<button className=" font-light text-slate-500 ml-1 px-1 rounded-full bg-slate-100" onClick={() => handleDelete(item)}>x</button></span>)
        )}
      </div>
      {options.length > 0 && focus && (
        <ul className="max-h-40 w-full border absolute rounded-lg z-50 bg-white overflow-y-scroll">
          {options?.map((item, index) => (
            <li
              key={item.id}
              className={`${value.includes(item) ? 'bg-slate-100': ''} border-b py-2 px-5`}
              onClick={() => handleSelect(item)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelect;
