import React, { useEffect, useState } from "react";
import { sortsDatas } from "../Datas";
import Uploader from "../Uploader";
import Loading from "../../screens/Loading";
import { Button, DatePickerComp, Input, Select } from "../Form";
import { BiChevronDown } from "react-icons/bi";
import { toast } from "react-hot-toast";
import { HiOutlineCheckCircle, HiOutlineXCircle } from "react-icons/hi";
import { getAge } from "../../utils/age";
import TwoColumRow from "../TwoColumRow";
import ThreeColumRow from "../ThreeColumRow";
import FourColumnRow from "../FourColumnRow";
import { useForm } from "react-hook-form";
import {
  createPatient,
  getPatientError,
  getPatientStatus,
  getPatients,
  updatePatient,
} from "../../redux/features/patientSlice";
import "react-confirm-alert/src/react-confirm-alert.css";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { usePatient } from "../../customHooks/queries/usePatient";

function PersonalInfo({ titles, operation }) {
  const [data, setData] = useState({});
  const { id } = useParams();
  const [date, setDate] = useState(new Date());
  const [status, setStatus] = useState(sortsDatas.maritalStatus[0]);
  const [gender, setGender] = useState(sortsDatas.genderFilter[0]);
  const [perfil, setPerfil] = useState(null);
  const [srcs, setSrcs] = useState(null);

  const navigate = useNavigate();

  const { data: patient, isLoading } = usePatient(id);

  //const patient = useSelector(getPatient);
  const patientStatus = useSelector(getPatientStatus);
  const patientError = useSelector(getPatientError);
  const dispatch = useDispatch();

  useEffect(() => {
    
    async function fetchData(id) {
      const { data } = patient;
      if (data) {
        setData(data);
        /* console.log(data.persona); */
        setValue("nombre", data.persona?.nombre);
        setValue("apellidos", data.persona?.apellidos);
        setValue(
          "ci",
          data.persona?.ci?.length > 10
            ? data.persona.ci.slice(0, 8)
            : data.persona.ci
        );
        setStatus(
          sortsDatas.maritalStatus.find(
            (stats) => stats.name === data.persona?.estado_civil
          )
        );
        setValue("complemento", data.persona?.complemento);
        setGender(
          sortsDatas.genderFilter.find(
            (sexo) => sexo.name === data.persona?.sexo
          )
        );
        setDate(data.persona?.fecha_nacimiento ? new Date(data.persona?.fecha_nacimiento) : new Date());
        setValue("email", data.persona?.email);
        setValue("telf1", data.persona?.telf1);
        setValue("direccion", data.persona?.direccion);
        setValue("referencia", data.persona?.referencia);
        setValue("tel_referencia", data.persona?.tel_referencia);
        {/* Datos del seguro */}
        /* setValue("num_seguro", data?.num_seguro);
        setValue("empresa_seg", data?.empresa_seg);
        setValue("ref_medica", data?.ref_medica); */
        setValue("residencia", data?.persona?.residencia);
        setValue("nacimiento", data?.persona?.nacimiento);
        setValue("ocupacion", data?.persona?.ocupacion);
        if (data.persona?.perfil) setSrcs(data.persona.perfil);
      }
    }

    if (operation === "edit") {
      fetchData(id);
    }
  }, [dispatch, patient?.data?.id]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nombre: data.nombre,
    },
  });

  if (isLoading) return <Loading />;
  const onSubmit = async (datas) => {
    datas.fecha_nacimiento = date?.toDateString();
    datas.sexo = gender.name;
    datas.estado_civil = status.name;
    if (perfil) {
      datas.archivo = perfil[0];
    }
    if (operation === "edit") {
      datas.id = patient.data.id;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui flex flex-col text-center gap-5 border border-gray-400 rounded-lg p-5 bg-white">
            <h1 className=" font-bold text-lg">
              {operation === "edit" ? "Editar" : "Crear"} Paciente
            </h1>
            <p>
              {operation === "edit"
                ? "Esta seguro de guardar los cambios?"
                : "Esta seguro de registrar al nuevo Paciente?"}
            </p>
            <div className="flex flex-row gap-5 lg:gap-10">
              <Button label={"No"} Icon={HiOutlineXCircle} onClick={onClose} />
              <Button
                label={"Si"}
                Icon={HiOutlineCheckCircle}
                onClick={async () => {
                  if (operation === "edit") {
                    const newData = await dispatch(updatePatient(datas));

                    if (newData?.error) {
                      toast.error(
                        `Ocurrio un error al Actualizar Paciente: ${newData.error}`,
                        {
                          duration: 5000,
                        }
                      );
                    } else {
                      dispatch(getPatients());
                      toast.success("Paciente Actualizado Satisfactoriamente", {
                        duration: 5000,
                      });
                      window.location.reload();
                      //navigate(-1);
                    }
                  } else {
                    const newData = await dispatch(createPatient(datas));
                    if (newData?.error) {
                      let errorMessage = "";

                      if (typeof patientError?.error === "string") {
                        errorMessage = patientError?.error;
                      } else if (typeof patientError?.error === "object") {
                        // Suponiendo que solo tienes un error por campo y te interesa el primero
                        for (const key in patientError?.error) {
                          if (patientError?.error[key]?.length > 0) {
                            errorMessage = patientError?.error[key];
                            break;
                          }
                        }
                      }
                      toast.error(
                        `Ocurrio un error al Registrar Paciente: ${errorMessage}`,
                        {
                          duration: 5000,
                        }
                      );
                    } else {
                      dispatch(getPatients());
                      toast.success("Paciente Creado Satisfactoriamente", {
                        duration: 5000,
                      });
                      navigate(`/patients`, { replace: true });
                      setTimeout(() => {
                        window.location.reload();
                      }, 5);
                    }
                  }
                  onClose();
                }}
              />
            </div>
          </div>
        );
      },
    });
  };

  return (
    <div className="flex-colo gap-4">
      {/* Names */}
      <TwoColumRow>
        <Input
          label="Nombres"
          color={true}
          type="text"
          //disabled={operation === "edit"}
          register={register("nombre", {
            required: "Este campo es requerido",
            maxLength: 30,
            value: data.nombre,
          })}
          error={errors.nombre}
        />
        <Input
          label="Apellidos"
          color={true}
          type="text"
          register={register("apellidos", {
            required: "Este campo es requerido",
            maxLength: 30,
            value: data.apellidos,
          })}
          error={errors.apellidos}
        />
      </TwoColumRow>
      {/* CI */}
      <FourColumnRow>
        <Input
          label="Carnet de Identidad"
          color={true}
          type="text"
          register={register("ci", {
            required: "Este campo es requerido",
            minLength: {
              value: 4,
              message: "El CI debe tener 4 caracteres como minimo.",
            },
          })}
          error={errors.ci}
        />
        <Input
          label="Complemento"
          color={true}
          type="text"
          register={register("complemento")}
        />

      {/* estado civil */}
      <div className="flex flex-col gap-3">
        <p className="text-black text-sm">Estado Civil</p>
          <Select
            selectedPerson={status}
            setSelectedPerson={setStatus}
            datas={sortsDatas.maritalStatus}
            >
            <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
            {status?.name} <BiChevronDown className="text-xl" />
              </div>
            </Select>
            </div>

      {/* gender */}
      <div className="flex w-full flex-col gap-3">
        <p className="text-black text-sm">Sexo</p>
        <Select
          selectedPerson={gender}
          setSelectedPerson={setGender}
          datas={sortsDatas.genderFilter}
        >
          <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
            {gender?.name} <BiChevronDown className="text-xl" />
          </div>
        </Select>
      </div>
      </FourColumnRow>

      <TwoColumRow>
        {/* email */}
        <Input
          label="Email"
          color={true}
          type="email"
          register={register("email", {
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: "No coincide con el formato requerido",
            },
          })}
          error={errors.email}
        />
        {/* phone */}
        <Input
          label="Teléfono"
          color={true}
          type="number"
          register={register("telf1", {
            min: {
              value: 60000000,
              message: "El numero de teléfono no es valido",
            },
          })}
          error={errors.telf1}
        />
      </TwoColumRow>

      <ThreeColumRow>
        {/* address */}
        <Input
          label="Domicilio"
          color={true}
          type="text"
          register={register("direccion")}
          error={errors.direccion}
        />
        {/* date */}

        <DatePickerComp
          label="Fecha de Nacimiento"
          startDate={date}
          onChange={setDate}
        />
        <Input
          label="Edad"
          color={true}
          type="text"
          value={`${getAge(date ?? new Date())} años`}
          disabled={true}
        />
      </ThreeColumRow>
      <ThreeColumRow>
        <Input
          label="Lugar de Nacimiento"
          color={true}
          type="text"
          register={register("nacimiento")}
        />
        <Input
          label="Lugar de Residencia"
          color={true}
          type="text"
          register={register("residencia")}
        />
        <Input
          label="Ocupación"
          color={true}
          type="text"
          register={register("ocupacion")}
        />
      </ThreeColumRow>

      {/* emergancy contact */}
      <TwoColumRow>
        <Input
          label="Contacto de Referencia"
          color={true}
          type="text"
          register={register("referencia")}
          error={errors.referencia}
        />
        <Input
          label="Teléfono de Contacto de Referencia"
          color={true}
          type="number"
          register={register("tel_referencia")}
          error={errors.tel_referencia}
        />
      </TwoColumRow>

          {/* Datos del seguro */}
      {/* <ThreeColumRow>
        <Input
          label="Numero de Seguro"
          color={true}
          type="text"
          register={register("num_seguro")}
        />
        <Input
          label="Empresa Aseguradora"
          color={true}
          type="text"
          register={register("empresa_seg")}
        />
        <Input
          label="Referencia Medica"
          color={true}
          type="text"
          register={register("ref_medica")}
        />
      </ThreeColumRow> */}

      <div className="flex gap-3 flex-col w-full col-span-6">
        <p className="text-sm">Imagen de Perfil</p>
        <Uploader
          image={perfil}
          setImage={setPerfil}
          // register={register("archivo")}
          srcs={srcs}
        />
      </div>
      {/* uploader */}
      {/* <TwoColumRow>
        <div className="flex gap-3 flex-col w-full col-span-6">
          <p className="text-sm">Imagen de Perfil</p>
          <Uploder
            image={perfil}
            setImage={setPerfil}
            register={register("perfil")}
          />
        </div>
        <div className="flex gap-3 flex-col w-full col-span-6">
          <p className="text-sm">CI Frontal</p>
          <Uploder
            image={ciFront}
            setImage={setCiFront}
            register={register("ci_front")}
          />
        </div>
      </TwoColumRow> */}

      {/* <TwoColumRow>
        <div className="flex gap-3 flex-col w-full col-span-6">
          <p className="text-sm">CI Posterior</p>
          <Uploder
            image={ciBack}
            setImage={setCiBack}
            register={register("ci_back")}
          />
        </div>
        <div className="flex gap-3 flex-col w-full col-span-6">
          <p className="text-sm">Carnet de Asegurado</p>
          <Uploder
            image={carnetAsegurado}
            setImage={setCarnetAsegurado}
            register={register("img_carnet_asegurado")}
          />
        </div>
      </TwoColumRow> */}
      {/* submit */}
      <div className="grid grid-cols-1 gap-4 w-full">
        {/* <Button
          label={"Baja"}
          Icon={RiDeleteBin5Line}
          onClick={() => {
            toast.error("aun no funciona");
          }}
        /> */}
        <Button
          label={operation === "edit" ? "Guardar" : "Registrar"}
          Icon={HiOutlineCheckCircle}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </div>
  );
}

export default PersonalInfo;
