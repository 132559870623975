import React, { useState } from "react";
import { MenuSelect, MenuSelect2 } from "./Form";
import { BiDotsHorizontalRounded } from "react-icons/bi";
import { FiEdit, FiEye } from "react-icons/fi";
import { RiDeleteBin6Line, RiDeleteBinLine, RiEdit2Line } from "react-icons/ri";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { getAge } from "../utils/age";
import { MdMedicalServices } from "react-icons/md";
import { FaFileDownload } from "react-icons/fa";
import Ecocardiograma from "../screens/Studies/Ecocardiograma";
import Modal from "./Modals/Modal";
import AnalisisGabinete from "../screens/Studies/AnalisisGabinete";
import { useDispatch } from "react-redux";
import { setPatient } from "../redux/features/patientSlice";
import Loading from "../screens/Loading";
import Laboratorio from "../screens/Studies/Laboratorio";

const thclass = "text-start text-sm font-medium py-3 px-2 whitespace-nowrap";
const tdclass = "text-start text-sm py-4 px-2 whitespace-nowrap";

export function Transactiontable({
  data,
  action,
  functions,
  status,
  uniquePatient,
}) {
  const DropDown1 = [
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: functions.delete,
    },
  ];
  return (
    <>
      {status === "loading" ? (
        <Loading />
      ) : data?.length > 0 ? (
        <table className="table-auto w-full mb-10">
          <thead className="bg-dry rounded-md overflow-hidden">
            <tr>
              <th className={thclass}>#</th>
              {!uniquePatient && <th className={thclass}>Paciente</th>}
              <th className={thclass}>Fecha</th>
              <th className={thclass}>Motivo</th>
              <th className={thclass}>
                Monto<span className="text-xs font-light">(bs)</span>
              </th>
              <th className={thclass}>Método</th>
              <th className={thclass}>Observaciones</th>
              {action && <th className={thclass}>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr
                key={item.id}
                className="border-b border-border hover:bg-greyed transitions"
              >
                <td className={tdclass}>{index + 1}</td>

                {!uniquePatient && (
                  <td className={tdclass}>
                    <div className="flex gap-4 items-center">
                      {/* <span className="w-12">
                  <img
                    src={item.user.image}
                    alt={item.user.title}
                    className="w-full h-12 rounded-full object-cover border border-border"
                  />
                </span> */}

                      <div>
                        <h4 className="text-sm font-medium">
                          {`${item?.patient?.persona?.nombre} ${item.patient?.persona?.apellidos}`}{" "}
                        </h4>
                        {/* <p className="text-xs mt-1 text-textGray">
                    {item.user.phone}
                  </p> */}
                      </div>
                    </div>
                  </td>
                )}
                <td className={tdclass}>
                  {new Date(item.created_at.slice(0, 19)).toLocaleDateString("en-GB")}
                </td>
                <td className={tdclass}>
                  <span
                    className={`py-1 px-4 ${
                      item.status === "Paid"
                        ? "bg-subMain text-subMain"
                        : item.status === "Pending"
                        ? "bg-orange-500 text-orange-500"
                        : item.status === "Cancel" && "bg-red-600 text-red-600"
                    } bg-opacity-10 text-xs rounded-xl`}
                  >
                    {item.motivo}
                  </span>
                </td>
                <td className={`${tdclass} font-semibold`}>{item.monto}</td>
                <td className={tdclass}>{item.metodo}</td>
                <td className={tdclass}>{item.observaciones}</td>
                {action && (
                  <td className={tdclass}>
                    <MenuSelect datas={DropDown1} item={item}>
                      <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                        <BiDotsHorizontalRounded />
                      </div>
                    </MenuSelect>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h2>Todavia no hay registros</h2>
      )}
    </>
  );
}

export function NotificationTable({ action, functions, notifications }) {
  const DropDown1 = [
    {
      title: "Editar",
      icon: FiEdit,
      onClick: (item) => {
        functions.edit(item);
      },
    },
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: (item) => {
        functions.baja(item.id);
      },
    },
  ];

  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>#</th>
          <th className={thclass}>Paciente</th>
          <th className={thclass}>Fecha</th>
          <th className={thclass}>Estado</th>
          <th className={thclass}>Hora</th>
          <th className={thclass}>Método</th>
          {action && <th className={thclass}>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {notifications.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>{index + 1}</td>
            <td className={tdclass}>
              <div className="flex gap-4 items-center">

                <div>
                  <h4 className="text-sm font-medium">{item?.patient?.persona?.nombre + " " + item?.patient?.persona?.apellidos}</h4>
                </div>
              </div>
            </td>
            <td className={tdclass}>{new Date(item.fecha).toLocaleDateString('en-gb')}</td>
            <td className={tdclass}>
              <span
                className={`py-1 px-4 ${
                  item.estado === "completado"
                    ? "bg-subMain text-subMain"
                    : item.estado === "pendiente"
                    ? "bg-orange-500 text-orange-500"
                    : item.estado === "baja" && "bg-red-600 text-red-600"
                } bg-opacity-10 text-xs rounded-xl`}
              >
                {item.estado}
              </span>
            </td>
            <td className={`${tdclass} font-semibold`}>{new Date(item.fecha).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</td>
            <td className={tdclass}>{`${item.sistema ? "Web": ""} ${item.sistema && item.mail ? "|": ""} ${item.mail ? "Email": ""}`}</td>
            {item?.tipo === "manual" && (
              <td className={tdclass}>
                <MenuSelect2 datas={DropDown1} item={item}>
                  <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                    <BiDotsHorizontalRounded />
                  </div>
                </MenuSelect2>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
// invoice table
export function InvoiceTable({ data }) {
  const navigate = useNavigate();
  const DropDown1 = [
    {
      title: "Editar",
      icon: FiEdit,
      onClick: (item) => {
        navigate(`/invoices/edit/${item.id}`);
      },
    },
    {
      title: "Ver",
      icon: FiEye,
      onClick: (item) => {
        navigate(`/invoices/preview/${item.id}`);
      },
    },
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: () => {
        toast.error("This feature is not available yet");
      },
    },
  ];
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Invoice ID</th>
          <th className={thclass}>Patient</th>
          <th className={thclass}>Created Date</th>
          <th className={thclass}>Due Date</th>
          <th className={thclass}>
            Amout <span className="text-xs font-light">(Tsh)</span>
          </th>
          <th className={thclass}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>#{item?.id}</td>
            <td className={tdclass}>
              <div className="flex gap-4 items-center">
                <span className="w-12">
                  <img
                    src={item?.to?.image}
                    alt={item?.to?.title}
                    className="w-full h-12 rounded-full object-cover border border-border"
                  />
                </span>
                <div>
                  <h4 className="text-sm font-medium">{item?.to?.title}</h4>
                  <p className="text-xs mt-1 text-textGray">
                    {item?.to?.email}
                  </p>
                </div>
              </div>
            </td>
            <td className={tdclass}>{item?.createdDate}</td>
            <td className={tdclass}>{item?.dueDate}</td>
            <td className={`${tdclass} font-semibold`}>{item?.total}</td>
            <td className={tdclass}>
              <MenuSelect datas={DropDown1} item={item}>
                <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                  <BiDotsHorizontalRounded />
                </div>
              </MenuSelect>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// prescription table
export function MedicineTable({ data, onEdit }) {
  const DropDown1 = [
    {
      title: "Editar",
      icon: FiEdit,
      onClick: (item) => {
        onEdit(item);
      },
    },
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: () => {
        toast.error("This feature is not available yet");
      },
    },
  ];
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Name</th>
          <th className={thclass}>
            Price <span className="text-xs font-light">(Tsh)</span>
          </th>
          <th className={thclass}>Status</th>
          <th className={thclass}>InStock</th>
          <th className={thclass}>Measure</th>
          <th className={thclass}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <h4 className="text-sm font-medium">{item?.name}</h4>
            </td>
            <td className={`${tdclass} font-semibold`}>{item?.price}</td>
            <td className={tdclass}>
              <span
                className={`text-xs font-medium ${
                  item?.status === "Out of stock"
                    ? "text-red-600"
                    : "text-green-600"
                }`}
              >
                {item?.status}
              </span>
            </td>
            <td className={tdclass}>{item?.stock}</td>
            <td className={tdclass}>{item?.measure}</td>
            <td className={tdclass}>
              <MenuSelect datas={DropDown1} item={item}>
                <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                  <BiDotsHorizontalRounded />
                </div>
              </MenuSelect>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// service table
export function ServiceTable({ data, onEdit }) {
  const DropDown1 = [
    {
      title: "Editar",
      icon: FiEdit,
      onClick: (item) => {
        onEdit(item);
      },
    },
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: () => {
        toast.error("This feature is not available yet");
      },
    },
  ];
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Name</th>
          <th className={thclass}>Created At</th>
          <th className={thclass}>
            Price <span className="text-xs font-light">(Tsh)</span>
          </th>
          <th className={thclass}>Status</th>
          <th className={thclass}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <h4 className="text-sm font-medium">{item?.name}</h4>
            </td>
            <td className={tdclass}>{item?.date}</td>
            <td className={`${tdclass} font-semibold`}>{item?.price}</td>
            <td className={tdclass}>
              <span
                className={`text-xs font-medium ${
                  !item?.status ? "text-red-600" : "text-green-600"
                }`}
              >
                {!item?.status ? "Disabled" : "Enabled"}
              </span>
            </td>
            <td className={tdclass}>
              <MenuSelect datas={DropDown1} item={item}>
                <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                  <BiDotsHorizontalRounded />
                </div>
              </MenuSelect>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// patient table
export function PatientTable({ data, functions, used }) {
  const dispatch = useDispatch();
  const DropDown1 = !used
    ? [
        {
          title: "Ver",
          icon: FiEye,
          onClick: (id) => {
            functions.preview(id);
            dispatch(setPatient(id));
          },
        },
        {
          title: "Editar",
          icon: RiEdit2Line,
          onClick: (id) => {
            functions.edit(id);
            dispatch(setPatient(id));
          },
        },
        {
          title: "Baja",
          icon: RiDeleteBin6Line,
          onClick: (id) => {
            functions.delete(id);
          },
        },
      ]
    : [
        {
          title: "View",
          icon: FiEye,
          onClick: (data) => {
            functions.preview(data.id);
          },
        },
      ];
  const thclasse = "text-start text-sm font-medium py-3 px-2 whitespace-nowrap";
  const tdclasse = "text-start text-xs py-4 px-2 whitespace-nowrap";
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hiden">
        <tr>
          <th className={thclasse}>#</th>
          <th className={thclasse}>Paciente</th>
          <th className={thclasse}>Celular</th>
          <th className={thclasse}>Sexo</th>
          {!used && (
            <>
              <th className={thclasse}>Fecha Nacimiento</th>
              <th className={thclasse}>Edad</th>
            </>
          )}

          <th className={thclasse}>Operaciones</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
            onClick={DropDown1.preview}
          >
            <td className={tdclasse}>{index + 1}</td>
            <td className={tdclasse}>
              <div className="flex gap-4 items-center">
                {/*!used && (
                  <span className="w-12">
                    <img
                      src={item.persona.perfil}
                      alt={`${item.persona.nombre} ${item.persona.apellidos}`}
                      className="w-full h-12 rounded-full object-cover border border-border"
                    />
                  </span>
                )*/}
                <Link
                  to={`/patients/preview/${item.id}`}
                  onClick={dispatch(setPatient(item.id))}
                >
                  <h4 className="text-sm font-medium">{`${item.persona.nombre} ${item.persona.apellidos}`}</h4>
                  <p className="text-xs mt-1 text-textGray">
                    {item.persona.ci.length > 10 ? item.persona.ci.slice(0,8): item.persona.ci }
                  </p>
                </Link>
              </div>
            </td>
            <td className={tdclasse}>{item.persona.telf1}</td>

            <td className={tdclasse}>
              <span
                className={`py-1 px-4 ${
                  item.persona.sexo === "Masculino"
                    ? "bg-subMain text-subMain"
                    : "bg-orange-500 text-orange-500"
                } bg-opacity-10 text-xs rounded-xl`}
              >
                {item.persona.sexo}
              </span>
            </td>
            {!used && (
              <>
                <td className={tdclasse}>
                  {new Date(item.persona.fecha_nacimiento).toLocaleDateString(
                    "en-GB"
                  )}
                </td>
                <td className={tdclasse}>
                  {getAge(new Date(item.persona.fecha_nacimiento))}
                </td>
              </>
            )}

            <td className={tdclasse}>
              <MenuSelect datas={DropDown1} item={item}>
                <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                  <BiDotsHorizontalRounded />
                </div>
              </MenuSelect>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// doctor table
export function DoctorsTable({ data, functions, doctor }) {
  const DropDown1 = [
    {
      title: "Ver",
      icon: FiEye,
      onClick: (data) => {
        functions.preview(data);
      },
    },
    {
      title: "Delete",
      icon: RiDeleteBin6Line,
      onClick: () => {
        toast.error("This feature is not available yet");
      },
    },
  ];
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>#</th>
          <th className={thclass}>{doctor ? "Doctor" : "Receptionist"}</th>
          <th className={thclass}>Created At</th>
          <th className={thclass}>Phone</th>
          <th className={thclass}>Title</th>
          <th className={thclass}>Email</th>
          <th className={thclass}>Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>{index + 1}</td>
            <td className={tdclass}>
              <div className="flex gap-4 items-center">
                <span className="w-12">
                  <img
                    src={item.user.image}
                    alt={item.user.title}
                    className="w-full h-12 rounded-full object-cover border border-border"
                  />
                </span>
                <h4 className="text-sm font-medium">{item.user.title}</h4>
              </div>
            </td>
            <td className={tdclass}>12 May, 2021</td>
            <td className={tdclass}>
              <p className="text-textGray">{item.user.phone}</p>
            </td>
            <td className={tdclass}>{item.title}</td>
            <td className={tdclass}>{item.user.email}</td>

            <td className={tdclass}>
              <MenuSelect datas={DropDown1} item={item}>
                <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                  <BiDotsHorizontalRounded />
                </div>
              </MenuSelect>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// appointment table
export function AppointmentTable({ data, functions, doctor, view = false }) {
  const DropDown1 = [
    {
      title: "Ver",
      icon: FiEye,
      onClick: (id) => {
        functions.preview(id);
      },
    },
    {
      title: "Atención",
      icon: MdMedicalServices,
      onClick: (id) => {
        functions.attention(id);
      },
    },
    {
      title: "Editar",
      icon: RiEdit2Line,
      onClick: (id) => {
        functions.edit(id);
      },
    },
    {
      title: "Baja",
      icon: RiDeleteBin6Line,
      onClick: (id) => {
        toast.error(`This feature is not available yet =>>>> id: ${id}`);
      },
    },
  ];

  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Fecha</th>
          <th className={thclass}>Estado</th>
          <th className={thclass}>Hora</th>
          {!view && <th className={`${thclass} mr-5`}>Opciones</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <p className="text-xs">
                {new Date(item?.fecha).toLocaleDateString("en-GB")}
              </p>
            </td>

            <td className={tdclass}>
              <span
                className={`py-1  px-4 ${
                  item.estado === "en curso"
                    ? "bg-subMain text-subMain"
                    : item.estado === "cerrada"
                    ? "bg-orange-500 text-orange-500"
                    : item.estado === "vencida" && "bg-red-600 text-red-600"
                } bg-opacity-10 text-xs rounded-xl`}
              >
                {item.estado}
              </span>
            </td>
            <td className={tdclass}>
              <p className="text-xs">{`${new Date(
                item.hora_inicio
              ).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })} - ${new Date(item.hora_fin).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`}</p>
            </td>
            {!view && (
              <td className={`${tdclass} mr-5`}>
                <MenuSelect datas={DropDown1} item={item}>
                  <div className="bg-dry border text-main text-xl py-2 px-4 rounded-lg">
                    <BiDotsHorizontalRounded />
                  </div>
                </MenuSelect>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// payment table
export function PaymentTable({ data, functions, doctor }) {
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Date</th>
          <th className={thclass}>{doctor ? "Patient" : "Doctor"}</th>
          <th className={thclass}>Status</th>
          <th className={thclass}>Amount</th>
          <th className={thclass}>Method</th>
          <th className={thclass}>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <p className="text-xs">{item.date}</p>
            </td>
            <td className={tdclass}>
              <h4 className="text-xs font-medium">
                {doctor ? item.user.title : item.doctor.title}
              </h4>
              <p className="text-xs mt-1 text-textGray">
                {doctor ? item.user.phone : item.doctor.phone}
              </p>
            </td>
            <td className={tdclass}>
              <span
                className={`py-1  px-4 ${
                  item.status === "Paid"
                    ? "bg-subMain text-subMain"
                    : item.status === "Pending"
                    ? "bg-orange-500 text-orange-500"
                    : item.status === "Cancel" && "bg-red-600 text-red-600"
                } bg-opacity-10 text-xs rounded-xl`}
              >
                {item.status}
              </span>
            </td>
            <td className={tdclass}>
              <p className="text-xs font-semibold">{`$${item.amount}`}</p>
            </td>
            <td className={tdclass}>
              <p className="text-xs">{item.method}</p>
            </td>

            <td className={tdclass}>
              <button
                onClick={() => functions.preview(item.id)}
                className="text-sm flex-colo bg-white text-subMain border rounded-md w-10 h-10"
              >
                <FiEye />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// invoice used table
export function InvoiceUsedTable({ data, functions }) {
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Invoice ID</th>
          <th className={thclass}>Create Date</th>
          <th className={thclass}>Due Date</th>
          <th className={thclass}>Amount</th>
          <th className={thclass}>Action</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclass}>
              <p className="text-xs">#{item.id}</p>
            </td>
            <td className={tdclass}>
              <p className="text-xs">{item.createdDate}</p>
            </td>
            <td className={tdclass}>
              <p className="text-xs">{item.dueDate}</p>
            </td>

            <td className={tdclass}>
              <p className="text-xs font-semibold">{`$${item.total}`}</p>
            </td>

            <td className={tdclass}>
              <button
                onClick={() => functions.preview(item.id)}
                className="text-sm flex-colo bg-white text-subMain border rounded-md w-10 h-10"
              >
                <FiEye />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// invoice table
export function InvoiceProductsTable({ data, functions, button }) {
  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclass}>Item</th>
          <th className={thclass}>
            Item Price
            <span className="text-xs font-light ml-1">(Tsh)</span>
          </th>
          <th className={thclass}>Quantity</th>
          <th className={thclass}>
            Amout
            <span className="text-xs font-light ml-1">(Tsh)</span>
          </th>
          {button && <th className={thclass}>Actions</th>}
        </tr>
      </thead>
      <tbody>
        {data?.map((item) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={`${tdclass}  font-medium`}>{item.name}</td>
            <td className={`${tdclass} text-xs`}>{item.price}</td>
            <td className={tdclass}>{item.id}</td>
            <td className={tdclass}>{item.price * item.id}</td>
            {button && (
              <td className={tdclass}>
                <button
                  onClick={() => functions.deleteItem(item.id)}
                  className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
                >
                  <RiDeleteBinLine />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

// medicine Dosage table

export function MedicineDosageTable({ data, functions, button }) {
  const thclasse = "text-start text-xs font-medium py-3 px-2 whitespace-nowrap";
  const tdclasse = "text-start text-xs py-4 px-2 whitespace-nowrap";

  return (
    <table className="table-auto w-full">
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclasse}>Item</th>
          <th className={thclasse}>Instrucciones</th>
          <th className={thclasse}>Cantidad</th>
          <th className={thclasse}>Presentación</th>

          {button && !data?.at(0)?.id && (
            <th className={thclasse}>Operación</th>
          )}
        </tr>
      </thead>
      <tbody>
        {data?.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclasse}>{item?.nombre}</td>
            <td className={tdclasse}>{item?.instrucciones}</td>
            <td className={tdclasse}>{item?.cantidad}</td>
            <td className={tdclasse}>{item?.presentacion}</td>

            {button && !data?.at(0)?.id && (
              <td className={tdclasse}>
                <button
                  onClick={() => functions.delete(index)}
                  className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
                >
                  <RiDeleteBinLine />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function LaboratoryTable({ data, functions, button, view, patient }) {
  const thclasse = "text-start text-xs font-medium py-3 px-2 whitespace-nowrap";
  const tdclasse = "text-start text-xs py-4 px-2 whitespace-nowrap";
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const todosEstudios = data;

  const renderModalContent = () => {
    switch (selected?.tipo) {
      case "Laboratorio":
        return <Laboratorio data={selected} onClose={() => setOpen(false)} />;
      case "Analisis de Gabinete":
        return <AnalisisGabinete data={selected} onClose={() => setOpen(false)}/>;
      default:
        return <h1>No encontrado</h1>;
    }
  };

  return (
    <>
      {open && (
        <Modal
          title={`Valores de ${selected?.tipo}`}
          isOpen={open}
          closeModal={() => {
            setOpen(false);
          }}
          width={"max-w-2xl"}
        >
          {renderModalContent()}
        </Modal>
      )}
      <table className="table-auto w-full">
        <thead className="bg-dry rounded-md overflow-hidden">
          <tr>
            <th className={thclasse}>#</th>
            {patient && <th className={thclasse}>Paciente</th>}
            <th className={thclasse}>Tipo</th>
            <th className={thclasse}>Nombre</th>
            {(view || patient) && <th className={thclasse}>Fecha</th>}
            {!patient && !view && button && <th className={thclasse}>Operación</th>}
            {!view && <th className={thclasse}>Pdf</th>}

          </tr>
        </thead>
        <tbody>
          {todosEstudios?.map((item, index) => (
            <tr
              key={`${item.id}_${item.tipo}`}
              className="border-b border-border hover:bg-greyed transitions"
            >
              <td className={tdclasse}>{index + 1}</td>
              {patient && <th className={thclasse}>{item?.cita?.patient?.persona?.nombre + " " + item?.cita?.patient?.persona?.apellidos}</th>}
              <td className={tdclasse}>{item?.tipo}</td>
              <td
                className={tdclasse + " cursor-pointer"}
                onClick={() => {
                  if (!view) {
                    setOpen(true);
                    setSelected(item);
                  }
                }}
              >
                {item?.nombre ?? item?.tipo}
              </td>
              {(view || patient) && (
                <td className={tdclasse}>
                  {new Date(item?.created_at).toLocaleDateString("en-GB")}
                </td>
              )}
              {!patient && !view && button && item?.id && (
                <td className={tdclasse}>
                  <button
                    onClick={() => functions.delete(item)}
                    className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
                  >
                    <RiDeleteBinLine />
                  </button>
                </td>
              )}
              {!view && item?.id && (
                <td>
                  <button
                    onClick={() => functions.printPdf(item)}
                    className="flex justify-center items-center bg-subMain rounded-full text-white text-center text-2xl p-2"
                  >
                    <FaFileDownload />
                  </button>
                </td>
              )}
              
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
export function LaboratoriesTable({
  data,
  functions,
  button,
  id,
  view = false,
}) {
  const thclasse = "text-start text-xs font-medium py-3 px-2 whitespace-nowrap";
  const tdclasse = "text-start text-xs py-4 px-2 whitespace-nowrap";
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [selected, setSelected] = useState(1);
  const datos = [
    {
      id: 1,
      nombre: "Laboratorio",
      tipo: "Laboratorio",
    },
    {
      id: 2,
      nombre: "Ecocardiograma",
      tipo: "Ecocardiograma",
    },
    {
      id: 3,
      nombre: "Electrocardiograma",
      tipo: "Electrocardiograma",
    },
    {
      id: 4,
      nombre: "Analisis de Gabinete",
      tipo: "Endoscopia",
    },
  ];
  return (
    <table className="table-auto w-full">
      {open && (
        <Modal
          title="Valores de Análisis"
          isOpen={open}
          closeModal={() => {
            setOpen(false);
          }}
          width={"max-w-lg"}
        >
          <Ecocardiograma />
        </Modal>
      )}
      {open2 && selected === 4 && (
        <Modal
          title="Valores de Análisis Gabinete"
          isOpen={open2}
          closeModal={() => {
            setOpen2(false);
          }}
          width={"max-w-lg"}
        >
          <AnalisisGabinete />
        </Modal>
      )}
      <thead className="bg-dry rounded-md overflow-hidden">
        <tr>
          <th className={thclasse}>#</th>
          {!id && <th className={thclasse}>Paciente</th>}
          <th className={thclasse}>Tipo</th>
          <th className={thclasse}>Nombre</th>
          <th className={thclasse}>Fecha</th>
          {button && !view && <th className={thclasse}>Operación</th>}
          {!view && <th className={thclasse}>Pdf</th>}
        </tr>
      </thead>
      <tbody>
        {datos?.map((item, index) => (
          <tr
            key={item.id}
            className="border-b border-border hover:bg-greyed transitions"
          >
            <td className={tdclasse}>{index + 1}</td>
            {!id && (
              <td
                className={thclasse}
                onClick={() => {
                  setSelected(item.id);
                  if (item.id === 4) setOpen2(true);
                  else setOpen(!open);
                }}
              >
                Nombre Paciente
              </td>
            )}
            <td
              className={tdclasse}
              onClick={() => {
                setSelected(item.id);
                if (item.id === 4) setOpen2(true);
                else setOpen(!open);
              }}
            >
              {item.nombre}
            </td>
            <td
              className={tdclasse}
              onClick={() => {
                setSelected(item.id);
                if (item.id === 4) setOpen2(true);
                else setOpen(!open);
              }}
            >
              {item?.tipo}
            </td>
            <td
              className={tdclasse}
              onClick={() => {
                setSelected(item.id);
                if (item.id === 4) setOpen2(true);
                else setOpen(!open);
              }}
            >
              {"21/10/2023"}
            </td>
            {button && !view && (
              <td className={tdclasse}>
                <button
                  onClick={() => functions.delete(item.id)}
                  className="bg-red-600 bg-opacity-5 text-red-600 rounded-lg border border-red-100 py-3 px-4 text-sm"
                >
                  <RiDeleteBinLine />
                </button>
              </td>
            )}
            {!view && (
              <td>
                <button className="flex justify-center items-center bg-subMain rounded-full text-white text-center text-2xl p-2">
                  <FaFileDownload />
                </button>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
